export enum WDocumentType {
  IN = "IN",
  OUT = "OUT",
  TRANSFER = "TRANSFER",
}

export enum CustomerType {
  BUYER = "BUYER",
  SUPPLIER = "SUPPLIER",
}
