import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import FileSaver from "file-saver";

const axiosClient: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
  headers: { "Content-Type": "application/json" },
});

axiosClient.interceptors.request.use((config) => {
  return {
    ...config,
    headers: { ...config.headers, Authorization: `Bearer ${localStorage.getItem("token")}` },
  } as InternalAxiosRequestConfig<any>;
});

export function saveBlob(response: AxiosResponse<Blob>) {
  const contentDisposition: string = response.headers['content-disposition'];
  const fileName: string
    = contentDisposition && contentDisposition.toLocaleLowerCase().indexOf('filename=') > 0
      ? contentDisposition.substring(contentDisposition.toLocaleLowerCase().indexOf('filename=') + 9)
      : 'file.dat';

  FileSaver.saveAs(response.data, fileName);
}

export default axiosClient;
