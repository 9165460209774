import React, { useMemo, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Stack } from "@mui/material";
import { GridActionsCellItem, GridColDef, GridColType, GridRowParams } from "@mui/x-data-grid";
import BackdropLoading from "components/elements/BackdropLoading";
import DataGrid from "components/elements/DataGrid";
import SnackbarCloseButton from "components/elements/SnackbarCloseButton";
import axiosClient from "config/AxiosClient";
import { useModalContext } from "context/ModalContext";
import { useUserDataContext } from "context/UserDataContext";
import {
  useAPIArticles,
  useAPICategories,
  useAPICurrencies,
  useAPIRemoveArticle,
  useAPIRemoveArticles,
  useAPISubcategories,
  useAPIUnits,
} from "hooks/useAPI";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar } from "notistack";
import { IArticle, IImportResult } from "types";

import AddModal from "./AddModal";
import HistoryModal from "./HistoryModal";

const Articles: React.FC = () => {
  const { showModal, closeModal } = useModalContext();
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const { isAdmin } = useUserDataContext();
  const { loading, data, refetch } = useAPIArticles();
  const [removeArticle] = useAPIRemoveArticle();
  const [removeArticles] = useAPIRemoveArticles();
  const confirm = useConfirm();
  const { data: categoriesData } = useAPICategories();
  const { data: subcategoriesData } = useAPISubcategories();
  const { data: currenciesData } = useAPICurrencies();
  const { data: unitsData } = useAPIUnits();

  const columns: GridColDef<IArticle>[] = useMemo(
    () => [
      { field: "id", headerName: "Kód", width: 70, hide: true },
      {
        field: "name",
        headerName: "Názov",
        flex: 1,
        renderCell: (params) => (
          <Box title="Zobraziť históriu" style={{ cursor: "pointer" }} onClick={() => handleHistory(params.row)}>
            {params.value}
          </Box>
        ),
      },
      {
        field: "category",
        headerName: "Kategória",
        type: "singleSelect",
        valueOptions: categoriesData?.categories.map((cat) => ({ value: cat.name, label: cat.name })) ?? [],
        valueGetter: (_value, row) => row.category.name ?? "",
        width: 75,
      },
      {
        field: "subcategory",
        headerName: "Podkategória",
        type: "singleSelect",
        valueOptions: subcategoriesData?.subcategories.map((sub) => ({ value: sub.name, label: sub.name })) ?? [],
        valueGetter: (_value, row) => row.subcategory.name ?? "",
        width: 100,
      },
      { field: "quantity", headerName: "Množstvo", type: "number", width: 75 },
      {
        field: "unit",
        headerName: "MJ",
        type: "singleSelect",
        valueOptions: unitsData?.units.map((unit) => ({ value: unit.name, label: unit.name })) ?? [],
        valueGetter: (_value, row) => row.unit.name ?? "",
        width: 70,
      },
      { field: "price", headerName: "Cena bez DPH", type: "number", width: 105 },
      { field: "priceVat", headerName: "Cena s DPH", type: "number", width: 90 },
      {
        field: "currency",
        headerName: "Mena",
        type: "singleSelect",
        valueOptions: currenciesData?.currencies.map((cur) => ({ value: cur.name, label: cur.name })) ?? [],
        valueGetter: (_value, row) => row.currency.name ?? "",
        width: 70,
      },
      { field: "manufacturer", headerName: "Výrobca", width: 70 },
      { field: "note", headerName: "Poznámka", width: 160 },
      ...(isAdmin
        ? [
            {
              field: "actions",
              type: "actions" as GridColType,
              width: 80,
              getActions: (params: GridRowParams<IArticle>) => [
                <GridActionsCellItem icon={<CreateIcon />} label="Edit" onClick={() => handleEdit(params.row)} />,
                <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => handleDelete(params.row)} />,
              ],
            },
          ]
        : []),
    ],
    [categoriesData, subcategoriesData, currenciesData, unitsData, confirm, isAdmin]
  );

  const getRowClassName = (params: { row: IArticle }) => {
    if (params.row.quantity === 0) return `row--error`;

    return "";
  };

  const handleCreate = () => {
    showModal(<AddModal handleClose={closeModal} refetch={refetch} />);
  };

  const handleEdit = (row: IArticle) => {
    showModal(<AddModal handleClose={closeModal} refetch={refetch} {...row} />);
  };

  const handleHistory = (row: IArticle) => {
    showModal(<HistoryModal handleClose={closeModal} id={row.id} name={row.name} />);
  };

  const handleDelete = (row: IArticle) => {
    confirm({
      title: "Naozaj chcete vymazať tento tovar?",
      description: `${row.name} bude vymazaný. Táto akcia je nevratná.`,
    })
      .then(async () => {
        try {
          await removeArticle({ variables: { removeArticleId: row.id } });
          refetch();
          enqueueSnackbar("Tovar bol vymazaný", { variant: "success" });
        } catch (e) {
          enqueueSnackbar("Tova sa nepodarilo vymazať", { variant: "error" });
        }
      })
      .catch(() => {
        /* ... */
      });
  };

  const handleImport = async (file: File) => {
    setIsImporting(true);
    try {
      const formData = new FormData();
      formData.append("file", file);

      const { data } = await axiosClient.post<IImportResult>("/files/import-articles", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (data.failImportedRows.length > 0 && data.imported === 0) {
        enqueueSnackbar("Import sa nepodaril", { variant: "error" });
      } else if (data.failImportedRows.length > 0 && data.imported > 0) {
        enqueueSnackbar(
          "Import bol čiastočne úspešný, nepodarilo sa importovať nasledujúce riadky: "
            + data.failImportedRows.join(", "),
          {
            variant: "warning",
            action: SnackbarCloseButton,
            persist: true,
          }
        );
        refetch();
      } else {
        enqueueSnackbar("Import bol úspešný", { variant: "success" });
        refetch();
      }
    } catch (error) {
      enqueueSnackbar("Import sa nepodaril", { variant: "error" });
    }
    setIsImporting(false);
  };

  const handleBulkDelete = () => {
    confirm({
      title: "Naozaj chcete vymazať tovary?",
      description: `Vybrané tovary (${selectedRows.length}) budú vymazané. Táto akcia je nevratná.`,
    })
      .then(async () => {
        try {
          const { data } = await removeArticles({ variables: { removeArticleIds: selectedRows } });
          refetch();

          if (data?.removeArticles?.nonDeletedIds && data?.removeArticles?.nonDeletedIds.length > 0) {
            enqueueSnackbar(
              `Nepodarilo sa vymazať nasledujúce tovary: ${data.removeArticles.nonDeletedIds.join(", ")}`,
              { variant: "warning", action: SnackbarCloseButton, persist: true }
            );
          } else {
            enqueueSnackbar("Vybrané tovary boli vymazané", { variant: "success" });
          }
        } catch (e) {
          enqueueSnackbar("Vybrané tovary sa nepodarilo vymazať", { variant: "error" });
        }
      })
      .catch(() => {
        /* ... */
      });
  };

  return (
    <>
      <DataGrid
        id="tovary-a-sluzby"
        title="Tovary a služby"
        actions={
          isAdmin && (
            <Stack direction={"row"} spacing={1}>
              <Button variant="contained" size="small" onClick={handleCreate} endIcon={<AddIcon />}>
                Pridať
              </Button>
              {selectedRows.length > 1 && (
                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  onClick={handleBulkDelete}
                  endIcon={<DeleteIcon />}>
                  {`Odstrániť (${selectedRows.length})`}
                </Button>
              )}
            </Stack>
          )
        }
        loading={loading}
        fullHeight
        rows={data?.articles ?? []}
        columns={columns}
        getRowClassName={getRowClassName}
        onImport={isAdmin ? handleImport : undefined}
        checkboxSelection={isAdmin}
        disableRowSelectionOnClick
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows = data?.articles.filter((row) => selectedIDs.has(row.id)).map((row) => row.id) ?? [];
          setSelectedRows(selectedRows);
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
      />
      <BackdropLoading loading={isImporting} />
    </>
  );
};

export default Articles;
