import React from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ApartmentIcon from "@mui/icons-material/Apartment";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Logout from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { useColorModeContext } from "context/ColorModeContext";
import { useModalContext } from "context/ModalContext";
import { useUserDataContext } from "context/UserDataContext";
import { useNavigate } from "react-router-dom";

import EditCompany from "./EditCompany";
import EditProfil from "./EditProfil";

interface HeaderProps {
  drawerWidth: number;
  handleDrawerToggle: () => void;
}

const Header: React.FC<HeaderProps> = ({ handleDrawerToggle }) => {
  const { showModal, closeModal } = useModalContext();
  const navigate = useNavigate();
  const theme = useTheme();
  const { toggleColorMode } = useColorModeContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { userData, isAdmin } = useUserDataContext();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleLogout = async (e: any) => {
    e.preventDefault();
    handleClose();

    try {
      localStorage.removeItem("token");
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleEditProfil = () => {
    showModal(<EditProfil handleClose={closeModal} />);
  };

  const handleEditCompany = () => {
    showModal(<EditCompany handleClose={closeModal} />);
  };

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <IconButton color="inherit" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: "none" } }}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" flex={1}>
          Sklad
        </Typography>
        <IconButton sx={{ ml: 1 }} onClick={toggleColorMode} color="inherit">
          {theme.palette.mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
        <Tooltip title={userData?.username}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}>
            <Avatar sx={{ width: 32, height: 32 }}>{userData?.username?.charAt(0)?.toUpperCase()}</Avatar>
          </IconButton>
        </Tooltip>
      </Toolbar>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        <Box
          px={2}
          py={1}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          color={alpha(theme.palette.text.primary, theme.palette.mode === "light" ? 0.54 : 1)}>
          <AccountCircleIcon fontSize="small" color="inherit" />
          <Stack pl={2}>
            <Typography variant="caption">Príhlasený ako</Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              {userData?.username}
            </Typography>
          </Stack>
        </Box>
        <Divider />
        <MenuItem onClick={handleEditProfil}>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          Nastaviť profil
        </MenuItem>
        {isAdmin && (
          <MenuItem onClick={handleEditCompany}>
            <ListItemIcon>
              <ApartmentIcon fontSize="small" />
            </ListItemIcon>
            Nastaviť firmu
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Odhlásiť sa
        </MenuItem>
      </Menu>
    </AppBar>
  );
};

export default Header;
