import React from "react";

import { Box, Stack, Typography, StackProps, Skeleton } from "@mui/material";
import { formatPostalCode } from "utils";

interface IProps {
  name: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
  ico?: string;
  dic?: string;
  stackProps?: StackProps;
  loading?: boolean;
}

const CompanyItem: React.FC<IProps> = ({ name, address, city, postalCode, country, ico, dic, stackProps, loading }) => {

  if (loading) {
    return (
      <Box>
        <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} width={100} />
        <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} width={350} />
        <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} width={200} />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="subtitle1" fontWeight={700}>
        {name}
      </Typography>
      <Stack spacing={1} {...stackProps}>
        <Stack>
          <Typography variant="body2">{address}</Typography>
          <Typography variant="body2">{`${formatPostalCode(postalCode)} ${city}`}</Typography>
          <Typography variant="body2">{country}</Typography>
        </Stack>
        <Stack>
          {ico && <Typography variant="body2">{`IČO: ${ico}`}</Typography>}
          {dic && <Typography variant="body2">{`DIČ: ${dic}`}</Typography>}
        </Stack>
      </Stack>
    </Box>
  );
};

export default CompanyItem;
