import React, { useState } from "react";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Paper, Stack, Box, Button, Avatar, TextField, Typography } from "@mui/material";
import { useUserDataContext } from "context/UserDataContext";
import { useAPILogin } from "hooks/useAPI";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
  const [mutateFunction] = useAPILogin();
  const navigate = useNavigate();
  const { setUserData } = useUserDataContext();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const { data } = await mutateFunction({ variables: { input: { username, password } } });
      if (data) {
        const { access_token, ...userData } = data.login;

        localStorage.setItem("token", access_token);
        setUserData?.(userData);
  
        enqueueSnackbar("Úspešne prihlásený", { variant: "success" });
        navigate("/");
      }
      
    } catch (error: any) {
      if (error?.message === "Unauthorized") {
        enqueueSnackbar("Nesprávne prihlasovacie údaje", { variant: "error" });
      } else {
        enqueueSnackbar("Nastala chyba, skúste to prosím neskôr", { variant: "error" });
      }
    }
  };

  const handleChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  return (
    <Paper sx={{ p: 2, mt: 8 }} elevation={2}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h5">Prihlásiť sa</Typography>
        <Stack width={"100%"} pt={1} spacing={2}>
          <TextField
            required
            fullWidth
            autoComplete="off"
            label="Používateľské meno"
            autoFocus
            value={username}
            onChange={handleChangeUsername}
          />
          <TextField
            required
            fullWidth
            label="Heslo"
            type="password"
            value={password}
            autoComplete="current-password"
            onChange={handleChangePassword}
          />
          <Button
            disabled={!username || !password}
            onClick={handleSubmit}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}>
            Prihlásiť sa
          </Button>
        </Stack>
      </Box>
    </Paper>
  );
};

export default SignIn;
