import React from "react";

import { Autocomplete, Box, Skeleton, Stack, TextField } from "@mui/material";
import { useAPICustomersByType } from "hooks/useAPI";
import { ICustomer, ICustomerBasic } from "types";
import { CustomerType } from "types/enums";

import CompanyItem from "../CompanyItem";

interface IProps {
  customer?: ICustomerBasic;
  onChangeCustomer: (data?: ICustomerBasic) => void;
  customerId?: number;
  onChangeCustomerId: (id?: number) => void;
  type: CustomerType;
}

const CustomerSection: React.FC<IProps> = ({ onChangeCustomerId, onChangeCustomer, customerId, customer, type }) => {
  const { data, loading } = useAPICustomersByType(type);
  const selectedCustomer = data?.customersByType.find((customer) => customer.id === customerId);

  const handleChangeCustomer = (_event: any, newValue: { label: string; id: number } | null) => {
    if (newValue) {
      onChangeCustomerId(newValue.id);
      onChangeCustomer(undefined);
    } else onChangeCustomerId(undefined);
  };

  const handleChangeCustomerData = (key: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChangeCustomer({ ...customer, [key]: e.target.value } as ICustomerBasic);
  };

  const getCustomerOptionLabel = (option: ICustomer) => `[${option.id}] ${option.name}${option.note ? ` - ${option.note}` : ""}`;

  if (loading) {
    return (
      <Box>
        <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} width={100} />
        <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} width={350} />
        <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} width={200} />
      </Box>
    );
  }

  return (
    <Stack>
      <Autocomplete
        size="small"
        value={selectedCustomer && customerId ? { label: getCustomerOptionLabel(selectedCustomer), id: customerId } : null}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={data?.customersByType.map((option) => ({ label: getCustomerOptionLabel(option), id: option.id })) || []}
        getOptionLabel={(option) => option.label}
        onChange={handleChangeCustomer}
        renderInput={(params) => <TextField {...params} />}
      />
      <Box pt={1}>
        {selectedCustomer && (
          <CompanyItem
            {...selectedCustomer}
            stackProps={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          />
        )}
        {!selectedCustomer && (
          <Stack spacing={1}>
            <TextField
              required
              size="small"
              label="Názov spoločnosti"
              value={customer?.name || ""}
              onChange={handleChangeCustomerData("name")}
            />
            <TextField
              required
              size="small"
              label="Adresa"
              value={customer?.address || ""}
              onChange={handleChangeCustomerData("address")}
            />
            <Stack spacing={1} direction="row">
              <TextField
                required
                sx={{ maxWidth: "35%" }}
                size="small"
                label="PSČ"
                type="number"
                value={customer?.postalCode || ""}
                onChange={handleChangeCustomerData("postalCode")}
              />
              <TextField
                required
                fullWidth
                size="small"
                label="Mesto"
                value={customer?.city || ""}
                onChange={handleChangeCustomerData("city")}
              />
            </Stack>
            <TextField
              required
              size="small"
              label="Krajina"
              value={customer?.country || ""}
              onChange={handleChangeCustomerData("country")}
            />
            <Stack spacing={1} direction="row">
              <TextField
                size="small"
                label="IČO"
                type="number"
                value={customer?.ico || ""}
                onChange={handleChangeCustomerData("ico")}
              />
              <TextField
                size="small"
                label="DIČ"
                type="number"
                value={customer?.dic || ""}
                onChange={handleChangeCustomerData("dic")}
              />
            </Stack>
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

export default CustomerSection;
