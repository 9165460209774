import Default from "components/layout/Default";
import Main from "components/layout/Main";
import Articles from "components/pages/Articles";
import Categories from "components/pages/Categories";
import Currencies from "components/pages/Currencies";
import Customers from "components/pages/Customers";
// import Dashboard from "components/pages/Dashboard";
import Documents from "components/pages/Documents";
import Inspections from "components/pages/Inspections";
import SingIn from "components/pages/SingIn";
import Subcategories from "components/pages/Subcategories";
import Units from "components/pages/Units";
import Warehouse from "components/pages/Warehouse";
import Warehouses from "components/pages/Warehouses";
import { useRoutes } from "react-router-dom";
import { CustomerType } from "types/enums";

const AppRoutes = () => {
  const routes = [
    {
      path: "/login",
      element: <Default />,
      children: [
        {
          path: "/login",
          element: <SingIn />,
        },
      ],
    },
    {
      path: "/",
      element: <Main />,
      children: [
        {
          path: "/",
          element: <Articles />,
        },
        {
          path: "/documents",
          element: <Documents />,
        },
        {
          path: "/buyers",
          element: <Customers type={CustomerType.BUYER} />,
        },
        {
          path: "/suppliers",
          element: <Customers type={CustomerType.SUPPLIER} />,
        },
        {
          path: "/warehouse/:id",
          element: <Warehouse />,
        },
        {
          path: "/settings/warehouses",
          element: <Warehouses />,
        },
        {
          path: "/settings/categories",
          element: <Categories />,
        },
        {
          path: "/settings/subcategories",
          element: <Subcategories />,
        },
        {
          path: "/settings/units",
          element: <Units />,
        },
        {
          path: "/settings/currencies",
          element: <Currencies />,
        },
        {
          path: "/inspections",
          element: <Inspections />,
        }
      ],
    },
  ];

  return useRoutes(routes);
};

export default AppRoutes;
