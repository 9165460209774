import React, { useMemo } from "react";

import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import { GridActionsCellItem, GridColDef, GridColType, GridRowParams } from "@mui/x-data-grid";
import DataGrid from "components/elements/DataGrid";
import { useModalContext } from "context/ModalContext";
import { useUserDataContext } from "context/UserDataContext";
import { useAPICategories, useAPIRemoveSubcategory, useAPISubcategories } from "hooks/useAPI";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar } from "notistack";
import { ISubcategory } from "types";

import AddModal from "./AddModal";
import EditModal from "./EditModal";

const Subcategories: React.FC = () => {
  const { showModal, closeModal } = useModalContext();
  const { isAdmin } = useUserDataContext();
  const { loading, data, refetch } = useAPISubcategories();
  const { data: categoriesData } = useAPICategories();
  const [removeSubcategory] = useAPIRemoveSubcategory();
  const confirm = useConfirm();

  const columns: GridColDef<ISubcategory>[] = useMemo(
    () => [
      { field: "id", headerName: "ID", flex: 1 },
      { field: "name", headerName: "Názov", flex: 1 },
      { field: "description", headerName: "Popis", flex: 1 },
      {
        field: "category",
        headerName: "Kategória",
        flex: 1,
        type: "singleSelect",
        valueOptions: categoriesData?.categories.map((cat) => ({ value: cat.name, label: cat.name })) ?? [],
        valueGetter: (_value, row) => row.category.name ?? "",
      },
      ...(isAdmin
        ? [
            {
              field: "actions",
              type: "actions" as GridColType,
              width: 80,
              getActions: (params: GridRowParams<ISubcategory>) => [
                <GridActionsCellItem icon={<CreateIcon />} label="Edit" onClick={() => handleEdit(params.row)} />,
                <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => handleDelete(params.row)} />,
              ],
            },
          ]
        : []),
    ],
    [isAdmin, categoriesData?.categories]
  );

  const handleCreate = () => {
    showModal(<AddModal handleClose={closeModal} refetch={refetch} />);
  };

  const handleEdit = (row: ISubcategory) => {
    showModal(<EditModal handleClose={closeModal} refetch={refetch} {...row} />);
  };

  const handleDelete = (row: ISubcategory) => {
    confirm({
      title: "Naozaj chcete vymazať túto subkategóriu?",
      description: `${row.name} bude vymazaná. Táto akcia je nevratná.`,
    })
      .then(async () => {
        try {
          await removeSubcategory({ variables: { removeSubcategoryId: row.id } });
          refetch();
          enqueueSnackbar("Subkategória bola vymazaná", { variant: "success" });
        } catch (e) {
          enqueueSnackbar("Subkategóriu sa nepodarilo vymazať", { variant: "error" });
        }
      })
      .catch(() => {
        /* ... */
      });
  };

  return (
    <DataGrid
      id="podkategorie"
      title={"Podkategórie"}
      actions={
        isAdmin ? (
          <Button variant="contained" size="small" onClick={handleCreate} endIcon={<AddIcon />}>
            Pridať
          </Button>
        ) : undefined
      }
      fullHeight
      loading={loading}
      rows={data?.subcategories ?? []}
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: "id",
              sort: "asc",
            },
          ],
        },
      }}
    />
  );
};

export default Subcategories;
