import { useEffect, useState } from "react";

import { Box, Divider, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import BackdropLoading from "components/elements/BackdropLoading";
import { useUserDataContext } from "context/UserDataContext";
import { useAPIUpdateUserInfo, useAPIUserInfo } from "hooks/useAPI";
import { enqueueSnackbar } from "notistack";

interface IProps {
  handleClose: () => void;
}

const EditProfil = ({ handleClose }: IProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [getInfo] = useAPIUserInfo();
  const { setUserData } = useUserDataContext();

  const [id, setId] = useState<number>(-1);
  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string | undefined>();

  const [updateUserInfo] = useAPIUpdateUserInfo();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getInfo();

        if (!data) {
          enqueueSnackbar("Nepodarilo sa načítať informácie o profile", { variant: "error" });
          handleClose();
        } else {
          setId(data.userInfo.id);
          setName(data.userInfo.name);
          setSurname(data.userInfo.surname);
          setEmail(data.userInfo.email);
          setLoading(false);
        }
      } catch (e) {
        enqueueSnackbar("Nepodarilo sa načítať informácie o profile", { variant: "error" });
        handleClose();
      }
    };

    fetchData();
  }, []);

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const { data } = await updateUserInfo({ variables: { input: { id, name, surname, email, newPassword: password } } });
      data?.updateUserInfo && setUserData?.(data?.updateUserInfo);
      handleClose();
      enqueueSnackbar("Profil bol upravený", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Profil sa nepodarilo upraviť", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const isPossibleToCreate = () => name.length > 0 && surname.length > 0 && email.length > 0;

  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <DialogTitle>Nastaviť profil</DialogTitle>
      <DialogContent>
        <Stack spacing={1} pt={1}>
          <TextField label="Meno" autoComplete="off" required value={name} onChange={(e) => setName(e.target.value)} />
          <TextField
            label="Priezvisko"
            autoComplete="off"
            required
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
          <TextField
            label="Email"
            autoComplete="off"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Box py={1}>
            <Divider />
          </Box>
          <TextField
            label="Nové heslo"
            autoComplete="off"
            required
            value={password ?? ""}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
        </Stack>
        <BackdropLoading loading={loading} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="small" onClick={handleClose}>
          Zrušiť
        </Button>
        <Button variant="contained" size="small" disabled={!isPossibleToCreate()} onClick={handleUpdate}>
          Uložiť
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditProfil;
