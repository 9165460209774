import React, { useMemo, useState } from "react";

import { PaletteMode } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { skSK } from "@mui/x-data-grid/locales";
import { ColorModeContext } from "context/ColorModeContext";
import { ConfirmProvider } from "material-ui-confirm";

interface IProps {
  children: React.ReactNode;
}

const getStoredMode = () => {
  const savedColorMode = localStorage.getItem("color-mode");
  return (savedColorMode ? savedColorMode : "light") as PaletteMode;
};

const MUITheme = ({ children }: IProps) => {
  const [mode, setMode] = useState<PaletteMode>(getStoredMode());

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === "light" ? "dark" : "light";
          localStorage.setItem("color-mode", newMode);
          return newMode;
        });
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme(
        {
          palette: {
            mode,
            background: {
              default: mode === "light" ? "#ECEDF6" : "#1E1E1E",
            },
          },
        },
        skSK
      ),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <ConfirmProvider
          defaultOptions={{
            confirmationText: "Áno",
            confirmationButtonProps: { color: "error", variant: "contained" },
            cancellationText: "Nie",
            cancellationButtonProps: { variant: "outlined" },
          }}>
          {children}
        </ConfirmProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default MUITheme;
