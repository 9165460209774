import { useState } from "react";

import { MenuItem, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import BackdropLoading from "components/elements/BackdropLoading";
import Select from "components/elements/Select";
import { useAPICategories, useAPIUpdateSubcategory } from "hooks/useAPI";
import { enqueueSnackbar } from "notistack";

interface IProps {
  handleClose: () => void;
  refetch: () => void;
  name: string;
  description?: string;
  id: number;
  categoryId: number;
}

const EditModal = ({ handleClose, refetch, id, ...props }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState(props.name);
  const [description, setDescription] = useState(props.description ?? "");
  const [categoryId, setCategoryId] = useState<number>(props.categoryId);
  const [updateSubcategory] = useAPIUpdateSubcategory();
  const { data } = useAPICategories();

  const handleUpdate = async () => {
    setLoading(true);
    try {
      await updateSubcategory({ variables: { input: { name, description, id, categoryId } } });
      handleClose();
      refetch();
      enqueueSnackbar("Kategória bola upravená", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Subkategóriu sa nepodarilo upraviť", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const isPossibleToCreate = () =>
    name.length > 0
    && categoryId !== undefined
    && (name !== props.name || description !== props.description || categoryId !== props.categoryId);

  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <DialogTitle>Upraviť</DialogTitle>
      <DialogContent>
        <Stack spacing={2} pt={1}>
          <TextField label="Názov" required value={name} onChange={(e) => setName(e.target.value)} />
          <TextField label="Popis" value={description} onChange={(e) => setDescription(e.target.value)} />
          <Select
            label="Kategória"
            value={categoryId}
            disabled={data?.categories.length === 0}
            onChange={(e) => setCategoryId(e.target.value as number)}>
            {data?.categories.map((cat) => (
              <MenuItem key={cat.id} value={cat.id}>
                {cat.name}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <BackdropLoading loading={loading} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="small" onClick={handleClose}>
          Zrušiť
        </Button>
        <Button variant="contained" size="small" disabled={!isPossibleToCreate()} onClick={handleUpdate}>
          Uložiť
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
