import { useEffect, useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  IconButton,
  MenuItem,
  Stack,
  Select as MUISelect,
  Typography,
  lighten,
  styled,
  useTheme,
  darken,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import { DatePicker } from "@mui/x-date-pickers";
import BackdropLoading from "components/elements/BackdropLoading";
import Select from "components/elements/Select";
import { useUserDataContext } from "context/UserDataContext";
import { useAPICreateDocument, useAPICurrencies, useAPIWarehouses } from "hooks/useAPI";
import { enqueueSnackbar } from "notistack";
import { ICurrency, IDocumentItems } from "types";
import { WDocumentType } from "types/enums";

import ItemInput from "../AddModal/ItemInput";

interface IProps {
  handleClose: () => void;
  refetch: () => void;
}

const SectionBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: theme.palette.mode === "light" ? lighten(theme.palette.primary.main, 0.8) : darken(theme.palette.primary.main, 0.8),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  alignContent: "center",
}));

const getCurrencyName = (data: ICurrency[], currencyId?: number) => {
  if (!currencyId) return undefined;

  const currency = data.find((currency) => currency.id === currencyId);
  return currency?.description ?? currency?.name ?? undefined;
};

const TransferModal = ({ handleClose, refetch }: IProps) => {
  const theme = useTheme();
  const { userData } = useUserDataContext();
  const { data: warehousesData } = useAPIWarehouses();
  const { data: currenciesData } = useAPICurrencies();
  const [createDocument] = useAPICreateDocument();

  const [loading, setLoading] = useState(false);
  const [createdAt, setCreatedAt] = useState<Date | null>(new Date());
  const [note, setNote] = useState("Skladový presun");
  const [currencyId, setCurrencyId] = useState<number | undefined>();
  const [items, setItems] = useState<IDocumentItems[]>([]);
  const [from, setFrom] = useState<number | undefined>();
  const [to, setTo] = useState<number | undefined>();
  const [comment, setComment] = useState("");

  const currency = getCurrencyName(currenciesData?.currencies ?? [], currencyId);

  useEffect(() => {
    if (currenciesData && currenciesData.currencies.length > 0) {
      setCurrencyId(currenciesData.currencies[0].id);
    }
  }, [currenciesData]);

  useEffect(() => {
    if (from) {
      setItems([]);
      setTo(undefined);
    }
  }, [from]);

  const handleCreate = async () => {
    if (!createdAt || !currencyId || !from || !to || !items.length || !userData?.id) return;
    setLoading(true);
    const price = items.reduce((acc, item) => acc + item.price * item.quantity, 0);
    try {
      await createDocument({
        variables: {
          createWarehouseDocumentInput: {
            createdAt: createdAt.toISOString(),
            note,
            currencyId,
            sourceWarehouseId: from,
            warehouseId: to,
            items,
            userId: userData?.id,
            price,
            type: WDocumentType.TRANSFER,
            comment,
          },
        },
      });
      handleClose();
      refetch();
      enqueueSnackbar(`Skladový presun bol vytvorený`, { variant: "success" });
    } catch (e) {
      enqueueSnackbar(`Skladový presun sa nepodarilo vytvoriť`, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const getTotalPrice = () => {
    const totalPrice = items.reduce((acc, item) => acc + item.price * item.quantity, 0);
    const value = totalPrice
      .toFixed(2)
      .replace(".", ",")
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    if (currency) return `${value} ${currency}`;
    return value;
  };

  const isPossibleToCreate = () =>
    createdAt && currencyId && from && items.length > 0 && items.every((item) => item.articleId !== -1) && to;

  const handleAddItem = () => setItems((prev) => [...prev, { price: 0, quantity: 1, articleId: -1 }]);
  const handleRemoveItem = (index: number) => setItems((prev) => prev.filter((_, i) => i !== index));
  const handleItemChange = (index: number, data: IDocumentItems) => {
    setItems((prev) => {
      const newItems = [...prev];
      newItems[index] = data;
      return newItems;
    });
  };

  return (
    <Dialog open={true} fullWidth maxWidth="md">
      <DialogTitle>Skladový presun</DialogTitle>
      <DialogContent>
        <Grid sx={{ mb: 1 }} container spacing={2}>
          <Grid xs={12} sm={6}>
            <Box pt={1}>
              <Select
                size="small"
                label="Zo skladu *"
                fullWidth
                value={from ?? ""}
                onChange={(e) => setFrom(e.target.value as number)}
                disabled={warehousesData?.warehouses.length === 0 || !warehousesData?.warehouses}>
                {warehousesData?.warehouses.map((warehouse) => (
                  <MenuItem key={warehouse.id} value={warehouse.id}>
                    {warehouse.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid xs={12} sm={6}>
            <Box pt={1}>
              <DatePicker
                label="Vystavená dňa *"
                sx={{ width: "100%" }}
                value={createdAt}
                onChange={(newValue) => setCreatedAt(newValue)}
                slotProps={{ textField: { size: "small" } }}
                timezone="Europe/Bratislava"
              />
            </Box>
          </Grid>
        </Grid>
        <Grid sx={{ my: 1 }} container spacing={2}>
          <Grid xs={12} sm={6}>
            <SectionBox width={"100%"}>
              <Typography variant="subtitle2" fontWeight={400}>
                Mena
              </Typography>
              <MUISelect
                sx={{ minWidth: "70px", backgroundColor: theme.palette.background.paper }}
                size="small"
                value={currencyId ?? ""}
                disabled={currenciesData?.currencies.length === 0 || !currenciesData?.currencies}
                onChange={(e) => setCurrencyId(e.target.value as number)}>
                {currenciesData?.currencies.map((currency) => (
                  <MenuItem key={currency.id} value={currency.id}>
                    {currency.name}
                  </MenuItem>
                ))}
              </MUISelect>
            </SectionBox>
          </Grid>
          <Grid xs={12} sm={6} height={"auto"}>
            <SectionBox width={"100%"} height={"100%"}>
              <Typography variant="subtitle2">Na sklad *</Typography>
            </SectionBox>
          </Grid>
        </Grid>
        <Grid sx={{ mb: 1 }} container spacing={2}>
          <Grid xs={12} sm={6} smOffset={6}>
            <Select
              size="small"
              label=""
              fullWidth
              value={to ?? ""}
              onChange={(e) => setTo(e.target.value as number)}
              disabled={warehousesData?.warehouses.length === 0 || !warehousesData?.warehouses}>
              {warehousesData?.warehouses.map((warehouse) => (
                <MenuItem key={warehouse.id} disabled={warehouse.id === from} value={warehouse.id}>
                  {warehouse.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <TextField sx={{ my: 1 }} size="small" fullWidth value={note} onChange={(e) => setNote(e.target.value)} />
        <Stack my={1}>
          <SectionBox>
            <Typography variant="subtitle2" fontWeight={400}>
              Položky
            </Typography>
            <IconButton size="small" onClick={handleAddItem}>
              <AddCircleIcon fontSize="small" />
            </IconButton>
          </SectionBox>
          <Stack pt={1}>
            {items.map((item, index) => (
              <ItemInput
                key={`item-${index}`}
                data={item}
                index={index}
                onChange={handleItemChange}
                onDelete={handleRemoveItem}
                warehouseId={from ?? -1}
                selectedItems={items.map((i) => i.articleId ?? -1)}
              />
            ))}
          </Stack>
        </Stack>
        <Box my={1} display={"flex"} justifyContent={"end"}>
          <SectionBox width={{ xs: "100%", sm: "50%" }}>
            <Typography variant="subtitle2">Celkom k úhrade</Typography>
            <Typography variant="subtitle2">{getTotalPrice()}</Typography>
          </SectionBox>
        </Box>
        <TextField sx={{ my: 1 }} size="small" label="Komentár" fullWidth value={comment} onChange={(e) => setComment(e.target.value)} />
        <Stack direction="column" sx={{ pt: 1 }}>
          <Typography sx={{ pb: 1 }} variant="subtitle1" fontWeight={700}>
            Vystavil:
          </Typography>
          <Typography
            variant="subtitle2"
            color={"text.secondary"}>{`${userData?.name} ${userData?.surname}`}</Typography>
          <Typography variant="subtitle2" color={"text.secondary"}>
            {userData?.email}
          </Typography>
        </Stack>
        <BackdropLoading loading={loading} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="small" onClick={handleClose}>
          Zrušiť
        </Button>
        <Button variant="contained" size="small" disabled={!isPossibleToCreate()} onClick={handleCreate}>
          Pridať
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransferModal;
