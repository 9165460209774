import { useEffect, useState } from "react";

import { Box, CircularProgress, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import BackdropLoading from "components/elements/BackdropLoading";
import { useAPIConstantsByCode, useAPIUpdateConstant } from "hooks/useAPI";
import { enqueueSnackbar } from "notistack";

interface IProps {
  handleClose: () => void;
}

const EditCompany = ({ handleClose }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { data: companyInfo, error } = useAPIConstantsByCode("COMPANY_INFO");
  const [isInit, setIsInit] = useState<boolean>(false);

  const [id, setId] = useState<number>(-1);
  const [name, setName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [ico, setIco] = useState<string>("");
  const [dic, setDic] = useState<string>("");

  const [updateConstant] = useAPIUpdateConstant();

  useEffect(() => {
    if (companyInfo && companyInfo?.constantByCode && companyInfo.constantByCode.value && !isInit) {
      try {
        const company = companyInfo ? JSON.parse(companyInfo.constantByCode.value) : undefined;
        if (company) {
          setId(companyInfo.constantByCode.id);
          setName(company.name);
          setAddress(company.address);
          setCity(company.city);
          setPostalCode(company.postalCode);
          setCountry(company.country);
          setIco(company.ico);
          setDic(company.dic);
          setIsInit(true);
        } else {
          enqueueSnackbar("Nastala chyba pri načítaní informácií o firme", { variant: "error" });
          handleClose();
        }
      } catch (e) {
        enqueueSnackbar("Nastala chyba pri načítaní informácií o firme", { variant: "error" });
        handleClose();
      }
    }
  }, [companyInfo]);

  useEffect(() => {
    if (error && !isInit) setIsInit(true);
  }, [error]);

  const handleUpdate = async () => {
    setLoading(true);
    try {
      await updateConstant({
        variables: {
          input: {
            id,
            code: "COMPANY_INFO",
            value: JSON.stringify({ name, address, city, postalCode, country, ico, dic }),
          },
        },
      });
      handleClose();
      enqueueSnackbar("Informácie o firme boli upravené", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Informácie o firme sa nepodarilo upraviť", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const isPossibleToCreate = () =>
    name.length > 0
    && address.length > 0
    && city.length > 0
    && postalCode.length > 0
    && country.length > 0
    && ico.length > 0
    && dic.length > 0;

  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <DialogTitle>Nastaviť informácie o firme</DialogTitle>
      <DialogContent>
        {!isInit && (
          <Box display={"flex"} justifyContent={"center"}>
            <CircularProgress />
          </Box>
        )}
        {isInit && (
          <Stack spacing={1} pt={1}>
            <TextField label="Názov" required value={name} onChange={(e) => setName(e.target.value)} />
            <TextField label="Adresa" required value={address} onChange={(e) => setAddress(e.target.value)} />
            <TextField label="Mesto" required value={city} onChange={(e) => setCity(e.target.value)} />
            <TextField label="PSČ" required value={postalCode} onChange={(e) => setPostalCode(e.target.value)} />
            <TextField label="Krajina" required value={country} onChange={(e) => setCountry(e.target.value)} />
            <TextField label="IČO" required value={ico} onChange={(e) => setIco(e.target.value)} />
            <TextField label="DIČ" required value={dic} onChange={(e) => setDic(e.target.value)} />
          </Stack>
        )}
        <BackdropLoading loading={loading} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="small" onClick={handleClose}>
          Zrušiť
        </Button>
        <Button variant="contained" size="small" disabled={!isPossibleToCreate()} onClick={handleUpdate}>
          Uložiť
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCompany;
