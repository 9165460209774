import React, { useMemo } from "react";

import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import { GridActionsCellItem, GridColDef, GridColType, GridRowParams } from "@mui/x-data-grid";
import DataGrid from "components/elements/DataGrid";
import { useModalContext } from "context/ModalContext";
import { useUserDataContext } from "context/UserDataContext";
import { useAPICategories, useAPIRemoveCategory } from "hooks/useAPI";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar } from "notistack";
import { ICategory } from "types";

import AddModal from "./AddModal";
import EditModal from "./EditModal";

const Categories: React.FC = () => {
  const { showModal, closeModal } = useModalContext();
  const { loading, data, refetch } = useAPICategories();
  const { isAdmin } = useUserDataContext();
  const [removeCategory] = useAPIRemoveCategory();
  const confirm = useConfirm();

  const columns: GridColDef<ICategory>[] = useMemo(
    () => [
      { field: "id", headerName: "ID", flex: 1 },
      { field: "name", headerName: "Názov", flex: 1 },
      { field: "description", headerName: "Popis", flex: 1 },
      ...(isAdmin
        ? [
            {
              field: "actions",
              type: "actions" as GridColType,
              width: 80,
              getActions: (params: GridRowParams<ICategory>) => [
                <GridActionsCellItem icon={<CreateIcon />} label="Edit" onClick={() => handleEdit(params.row)} />,
                <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => handleDelete(params.row)} />,
              ],
            },
          ]
        : []),
    ],
    [isAdmin]
  );

  const handleCreate = () => {
    showModal(<AddModal handleClose={closeModal} refetch={refetch} />);
  };

  const handleEdit = (row: ICategory) => {
    showModal(<EditModal handleClose={closeModal} refetch={refetch} {...row} />);
  };

  const handleDelete = (row: ICategory) => {
    confirm({
      title: "Naozaj chcete vymazať túto kategóriu?",
      description: `${row.name} bude vymazaná. Táto akcia je nevratná.`,
    })
      .then(async () => {
        try {
          await removeCategory({ variables: { removeCategoryId: row.id } });
          refetch();
          enqueueSnackbar("Kategória bola vymazaná", { variant: "success" });
        } catch (e) {
          enqueueSnackbar("Kategóriu sa nepodarilo vymazať", { variant: "error" });
        }
      })
      .catch(() => {
        /* ... */
      });
  };

  return (
    <>
      <DataGrid
        id="kategorie"
        title={"Kategórie"}
        actions={
          isAdmin ? (
            <Button variant="contained" size="small" onClick={handleCreate} endIcon={<AddIcon />}>
              Pridať
            </Button>
          ) : undefined
        }
        fullHeight
        loading={loading}
        rows={data?.categories ?? []}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: "id",
                sort: "asc",
              },
            ],
          },
        }}
      />
    </>
  );
};

export default Categories;
