import React, { useMemo, useRef, useState } from "react";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  styled,
} from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import DataGrid from "components/elements/DataGrid";
import Switch from "components/elements/Switch";
import axiosClient, { saveBlob } from "config/AxiosClient";
import { useModalContext } from "context/ModalContext";
import { useAPICurrencies, useAPIDocuments, useAPIWarehouses } from "hooks/useAPI";
import { enqueueSnackbar } from "notistack";
import { IDocument } from "types";
import { WDocumentType } from "types/enums";
import { formatDate } from "utils";

import AddModal from "./AddModal";
import DetailModal from "./DetailModal";
import EditModal from "./EditModal";
import TransferEditModal from "./TransferEditModal";
import TransferModal from "./TransferModal";

const ArrowLeftAltIcon = styled(ArrowRightAltIcon)({ transform: "rotate(180deg)" });

export const TYPE_OPTIONS = [
  { value: WDocumentType.IN, label: "Príjemka" },
  { value: WDocumentType.OUT, label: "Výdajka" },
  { value: WDocumentType.TRANSFER, label: "Presun" },
];

const getAlreadyReadIds = () => {
  const ids = localStorage.getItem("readIds");

  if (ids) return JSON.parse(ids) as number[];
  return [];
};

const Documents: React.FC = () => {
  const { showModal, closeModal } = useModalContext();
  const anchorRef = useRef<HTMLButtonElement>(null);

  const [open, setOpen] = useState(false);
  const [showUnread, setShowUnread] = useState(localStorage.getItem("showUnread") === "true");
  const [readIds, setReadIds] = useState<number[]>(getAlreadyReadIds());

  const { loading, data, refetch } = useAPIDocuments();
  const { data: currenciesData } = useAPICurrencies();
  const { data: warehousesData } = useAPIWarehouses();

  const columns: GridColDef<IDocument>[] = useMemo(
    () => [
      {
        field: "number",
        headerName: "Číslo",
        width: 95,
      },
      {
        field: "type",
        headerName: "Typ",
        type: "singleSelect",
        valueOptions: TYPE_OPTIONS,
        valueFormatter: (_value, row) => TYPE_OPTIONS.find((opt) => opt.value === row.type)?.label ?? row.type,
        width: 80,
      },
      {
        field: "customer",
        headerName: "Partner/Sklad",
        valueGetter: (_value, row) => {
          if (row.type === WDocumentType.TRANSFER) {
            return row.sourceWarehouse?.name ?? "";
          } else {
            if (row.customer?.note) return row.customer?.name + " - " + row.customer?.note;
            return row.customer?.name ?? "";
          }
        },
        flex: 1,
      },
      {
        field: "direction",
        headerName: "Smer",
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        width: 60,
        align: "center",
        renderCell: (params) => (
          <Box height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            {params.row.type === WDocumentType.OUT ? <ArrowLeftAltIcon /> : <ArrowRightAltIcon />}
          </Box>
        ),
      },
      {
        field: "warehouse",
        headerName: "Sklad",
        valueGetter: (_value, row) => row.warehouse.name ?? "",
        flex: 1,
      },
      {
        field: "price",
        headerName: "Cena",
        type: "number",
        width: 70,
      },
      {
        field: "currency",
        headerName: "Mena",
        type: "singleSelect",
        valueOptions: currenciesData?.currencies.map((cur) => ({ value: cur.name, label: cur.name })) ?? [],
        valueGetter: (_value, row) => row.currency.name ?? "",
        width: 70,
      },
      {
        field: "creator",
        headerName: "Vystavil",
        width: 120,
      },
      {
        field: "createdAt",
        headerName: "Vytvorené",
        type: "date",
        valueFormatter: (_value, row) => (row.createdAt ? formatDate(row.createdAt) : row.createdAt),
        width: 100,
      },
      {
        field: "updatedAt",
        headerName: "Upravené",
        type: "date",
        width: 100,
        valueFormatter: (_value, row) => (row.updatedAt ? formatDate(row.updatedAt) : row.updatedAt),
      },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={() => handleEdit(params.row)} />,
          <GridActionsCellItem icon={<DownloadIcon />} label="Download" onClick={() => handleDownload(params.row)} />,
        ],
      },
    ],
    [currenciesData, warehousesData]
  );

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) return;
    setOpen(false);
  };

  const handleAdd = (type: WDocumentType.IN | WDocumentType.OUT) => () => {
    showModal(<AddModal handleClose={closeModal} refetch={refetch} type={type} />);
    handleToggle();
  };

  const handleTransfer = () => () => {
    showModal(<TransferModal handleClose={closeModal} refetch={refetch} />);
    handleToggle();
  };

  const handleDownload = (row: IDocument) => {
    if (row.type === WDocumentType.TRANSFER) handleDownloadTransfer(row.id);
    else handleDownloadNormal(row.id);
  };

  const handleEdit = (row: IDocument) => {
    if (row.type === WDocumentType.TRANSFER)
      showModal(<TransferEditModal handleClose={closeModal} refetch={refetch} data={row} />);
    else showModal(<EditModal handleClose={closeModal} refetch={refetch} data={row} />);
  };

  const handleCloseDetail = (id: number) => {
    setReadIds([...readIds, id]);
    localStorage.setItem("readIds", JSON.stringify([...readIds, id]));
    closeModal();
  };

  const handleDetail = (row: IDocument) => {
    showModal(<DetailModal handleClose={() => handleCloseDetail(row.id)} data={row} />);
  };

  const handleDownloadNormal = async (id: number) => {
    try {
      const response = await axiosClient.get(`/files/document/${id}`, { responseType: "blob" });
      if (response) saveBlob(response);
    } catch (error) {
      enqueueSnackbar("Chyba pri sťahovaní súboru", { variant: "error" });
    }
  };

  const handleDownloadTransfer = async (id: number) => {
    try {
      const response = await axiosClient.get(`/files/document-transfer/${id}`, { responseType: "blob" });
      if (response) saveBlob(response);
    } catch (error) {
      enqueueSnackbar("Chyba pri sťahovaní súboru", { variant: "error" });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowUnread(event.target.checked);
    localStorage.setItem("showUnread", event.target.checked.toString());
  }

  const getRowClassName = (params: { row: IDocument }) => {
    if (!showUnread) return "";

    if (!readIds.includes(params.row.id)) return "row--unread";
    return "";
  };

  return (
    <>
      <DataGrid
        id="prijemky-vydajky-presun"
        title="Príjem, výdaj a presun"
        actions={
          <Stack spacing={1} direction="row" alignItems="center">
            <Button
              ref={anchorRef}
              variant="contained"
              size="small"
              onClick={handleToggle}
              endIcon={<KeyboardArrowDownIcon />}>
              Pridať
            </Button>
            <Switch checked={showUnread} onChange={handleChange} label="Zvýrazniť neprečítané" />
          </Stack>
        }
        loading={loading}
        fullHeight
        rows={data?.warehouseDocuments ?? []}
        columns={columns}
        onRowClick={(params) => handleDetail(params.row as IDocument)}
        getCellClassName={getRowClassName}
        disableRowSelectionOnClick
      />
      <Popper open={open} anchorEl={anchorRef.current} placement="bottom-start" transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open}>
                  <MenuItem onClick={handleAdd(WDocumentType.IN)}>
                    <ListItemIcon>
                      <ArrowLeftAltIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Príjemka</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleAdd(WDocumentType.OUT)}>
                    <ListItemIcon>
                      <ArrowRightAltIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Výdajka</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleTransfer()}>
                    <ListItemIcon>
                      <MoveDownIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Presun</ListItemText>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default Documents;
