import React, { useEffect, useState } from "react";

import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InventoryIcon from "@mui/icons-material/Inventory";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Toolbar,
} from "@mui/material";
import { useAPIWarehouses } from "hooks/useAPI";
import { useLocation, useNavigate } from "react-router-dom";

interface LeftMenuProps {
  drawerWidth: number;
  mobileOpen: boolean;
  setIsClosing: (isClosing: boolean) => void;
  setMobileOpen: (mobileOpen: boolean) => void;
}

const LeftMenu: React.FC<LeftMenuProps> = ({ drawerWidth, mobileOpen, setIsClosing, setMobileOpen }) => {
  const { loading, data } = useAPIWarehouses();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [openWarehouse, setOpenWarehouse] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openCustomers, setOpenCustomers] = useState(false);

  useEffect(() => {
    if (pathname.includes("/warehouse/")) setOpenWarehouse(true);
    if (pathname.includes("/settings/")) setOpenSettings(true);
    if (pathname.includes("/suppliers") || pathname.includes("/buyers")) setOpenCustomers(true);
  }, [pathname]);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton selected={pathname === "/"} onClick={() => navigate("/")}>
            <ListItemIcon>
              <InventoryIcon />
            </ListItemIcon>
            <ListItemText primary={"Tovary a služby"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton selected={pathname === "/documents"} onClick={() => navigate("/documents")}>
            <ListItemIcon>
              <SwapHorizIcon />
            </ListItemIcon>
            <ListItemText primary={"Príjem, výdaj a presun"} />
          </ListItemButton>
        </ListItem>
        <ListItemButton onClick={() => setOpenWarehouse(!openWarehouse)}>
          <ListItemIcon>
            <WarehouseIcon />
          </ListItemIcon>
          <ListItemText primary="Sklady" />
          {openWarehouse ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openWarehouse} timeout="auto" unmountOnExit>
          <List component="div" disablePadding dense>
            {loading ? (
              <Skeleton sx={{ mx: 4, my: 1 }} height={"32px"} />
            ) : (
              data?.warehouses.map((warehouse) => (
                <ListItemButton
                  key={warehouse.id}
                  sx={{ pl: 9 }}
                  selected={pathname === `/warehouse/${warehouse.id}`}
                  onClick={() => navigate(`/warehouse/${warehouse.id}`)}>
                  <ListItemText primary={warehouse.name} />
                </ListItemButton>
              ))
            )}
          </List>
        </Collapse>
        <ListItem disablePadding>
          <ListItemButton selected={pathname === "/inspections"} onClick={() => navigate("/inspections")}>
            <ListItemIcon>
              <EventAvailableIcon />
            </ListItemIcon>
            <ListItemText primary={"Ročná prehliadka"} />
          </ListItemButton>
        </ListItem>
        <ListItemButton onClick={() => setOpenCustomers(!openCustomers)}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Zákazníci" />
          {openCustomers ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openCustomers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding dense>
            <ListItemButton sx={{ pl: 9 }} selected={pathname === `/suppliers`} onClick={() => navigate(`/suppliers`)}>
              <ListItemText primary={"Dodávatelia"} />
            </ListItemButton>
            <ListItemButton sx={{ pl: 9 }} selected={pathname === `/buyers`} onClick={() => navigate(`/buyers`)}>
              <ListItemText primary={"Odberatelia"} />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
      <Divider />
      <List>
        <ListItemButton onClick={() => setOpenSettings(!openSettings)}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Nastavenie" />
          {openSettings ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openSettings} timeout="auto" unmountOnExit>
          <List component="div" disablePadding dense>
            <ListItemButton
              sx={{ pl: 9 }}
              onClick={() => navigate("/settings/warehouses")}
              selected={pathname === "/settings/warehouses"}>
              <ListItemText primary={"Sklady"} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 9 }}
              onClick={() => navigate("/settings/categories")}
              selected={pathname === "/settings/categories"}>
              <ListItemText primary={"Kategórie"} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 9 }}
              onClick={() => navigate("/settings/subcategories")}
              selected={pathname === "/settings/subcategories"}>
              <ListItemText primary={"Podkategórie"} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 9 }}
              onClick={() => navigate("/settings/currencies")}
              selected={pathname === "/settings/currencies"}>
              <ListItemText primary={"Meny"} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 9 }}
              onClick={() => navigate("/settings/units")}
              selected={pathname === "/settings/units"}>
              <ListItemText primary={"Jednotky"} />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
    </div>
  );

  return (
    <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}>
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        open>
        {drawer}
      </Drawer>
    </Box>
  );
};

export default LeftMenu;
