import { useState } from "react";

import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import BackdropLoading from "components/elements/BackdropLoading";
import { useAPICreateWarehouse, useAPIUpdateWarehouse } from "hooks/useAPI";
import { enqueueSnackbar } from "notistack";
import { IWarehouseInput } from "types";

interface IProps extends Partial<IWarehouseInput> {
  handleClose: () => void;
  refetch: () => void;
}

const AddModal = ({ handleClose, refetch, id, ...props }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [name, setName] = useState<string>(props.name ?? "");
  const [description, setNote] = useState<string>(props.description ?? "");

  const [createWarehouse] = useAPICreateWarehouse();
  const [updateWarehouse] = useAPIUpdateWarehouse();

  const handleCreate = async () => {
    if (!name) return;

    setLoading(true);
    try {
      await createWarehouse({
        variables: {
          createWarehouseInput: {
            name,
            description,
          },
        },
      });
      handleClose();
      refetch();
      enqueueSnackbar("Sklad bol úspešne vytvorený", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Sklad sa nepodarilo vytvoriť", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    if (!name) return;

    setLoading(true);
    try {
      await updateWarehouse({
        variables: {
          updateWarehouseInput
          : {
            id,
            name,
            description
          },
        },
      });
      handleClose();
      refetch();
      enqueueSnackbar("Sklad bol úspešne upravený", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Sklad sa nepodarilo upraviť", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const isPossibleToSubmit = () => name.length > 0;

  const handleSubmit = () => (id ? handleUpdate() : handleCreate());

  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <DialogTitle>{id ? "Upraviť" : "Pridať"}</DialogTitle>
      <DialogContent>
        <Stack spacing={1} pt={1}>
          <TextField fullWidth label="Názov" required value={name} onChange={(e) => setName(e.target.value)} />
          <TextField
            fullWidth
            label="Popis"
            value={description}
            onChange={(e) => setNote(e.target.value)}
            rows={3}
            multiline
          />
        </Stack>
        <BackdropLoading loading={loading} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="small" onClick={handleClose}>
          Zrušiť
        </Button>
        <Button variant="contained" size="small" disabled={!isPossibleToSubmit()} onClick={handleSubmit}>
          {id ? "Upraviť" : "Vytvoriť"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddModal;
