import { ApolloClient, InMemoryCache, from, createHttpLink } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    //Redirect to login page after token expiration
    if (graphQLErrors.some((e) => e.message === "Unauthorized")) {
      if (window.location.pathname !== "/login") {
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
    }
  }
});

const appLink = from([errorLink, authLink.concat(httpLink)]);

const client = new ApolloClient({
  link: appLink,
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

export default client;