import React, { useState, useContext, createContext } from "react";

import { IUserData, IUserDataContext } from "types";

const defaultState: IUserDataContext = { isAdmin: false };

const UserDataContext = createContext<IUserDataContext>(defaultState);

interface IProps {
  children: React.ReactNode;
}

export const UserDataProvider = ({ children }: IProps) => {
  const [userData, setUserData] = useState<IUserData | undefined>(defaultState.userData);

  return (
    <UserDataContext.Provider
      value={{
        userData,
        setUserData,
        isAdmin: userData?.role === "admin",
      }}>
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserDataContext = () => useContext(UserDataContext);
