import { useMemo, useState } from "react";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import DownloadIcon from "@mui/icons-material/Download";
import { styled } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import DataGrid from "components/elements/DataGrid";
import { TYPE_OPTIONS } from "components/pages/Documents";
import DetailModal from "components/pages/Documents/DetailModal";
import axiosClient, { saveBlob } from "config/AxiosClient";
import { useAPICurrencies, useAPIDocumentsByCustomer, useAPIWarehouses } from "hooks/useAPI";
import { enqueueSnackbar } from "notistack";
import { IDocument } from "types";
import { CustomerType, WDocumentType } from "types/enums";
import { formatDate } from "utils";

const ArrowLeftAltIcon = styled(ArrowRightAltIcon)({ transform: "rotate(180deg)" });

interface IProps {
  handleClose: () => void;
  type: CustomerType;
  name: string;
  id: number;
}

const HistoryModal = ({ handleClose, id, type, name }: IProps) => {
  const { data: currenciesData } = useAPICurrencies();
  const { data: warehousesData } = useAPIWarehouses();
  const { data, loading } = useAPIDocumentsByCustomer(id);

  const [selectedRow, setSelectedRow] = useState<IDocument | null>(null);

  const columns: GridColDef<IDocument>[] = useMemo(
    () => [
      {
        field: "number",
        headerName: "Číslo",
        flex: 1,
      },
      {
        field: "type",
        headerName: "Typ",
        flex: 1,
        type: "singleSelect",
        valueOptions: TYPE_OPTIONS,
        valueFormatter: (_value, row) => TYPE_OPTIONS.find((opt) => opt.value === row.type)?.label ?? row.type,
      },
      {
        field: "customer",
        headerName: "Sklad",
        flex: 1,
        valueGetter: (_value, row) => {
          if (row.type === WDocumentType.TRANSFER) {
            return row.sourceWarehouse?.name ?? "";
          } else {
            return row.customer?.name ?? "";
          }
        },
      },
      {
        field: "direction",
        headerName: "Smer",
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        width: 60,
        align: "center",
        renderCell: (params) => (params.row.type === WDocumentType.OUT ? <ArrowLeftAltIcon /> : <ArrowRightAltIcon />),
      },
      {
        field: "warehouse",
        headerName: "Sklad",
        flex: 1,
        valueGetter: (_value, row) => row.warehouse.name ?? "",
      },
      {
        field: "price",
        headerName: "Cena",
        flex: 1,
        type: "number",
      },
      {
        field: "currency",
        headerName: "Mena",
        flex: 1,
        type: "singleSelect",
        valueOptions: currenciesData?.currencies.map((cur) => ({ value: cur.name, label: cur.name })) ?? [],
        valueGetter: (_value, row) => row.currency.name ?? "",
      },
      {
        field: "creator",
        headerName: "Vystavil",
        flex: 1,
      },
      {
        field: "createdAt",
        headerName: "Vytvorené",
        flex: 1,
        type: "date",
        valueFormatter: (_value, row) => (row.createdAt ? formatDate(row.createdAt) : row.createdAt),
      },
      {
        field: "updatedAt",
        headerName: "Upravené",
        flex: 1,
        type: "date",
        valueFormatter: (_value, row) => (row.updatedAt ? formatDate(row.updatedAt) : row.updatedAt),
      },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem icon={<DownloadIcon />} label="Download" onClick={() => handleDownload(params.row.id)} />,
        ],
      },
    ],
    [currenciesData, warehousesData]
  );

  const handleDownload = async (id: number) => {
    try {
      const response = await axiosClient.get(`/files/document/${id}`, { responseType: "blob" });
      if (response) saveBlob(response);
    } catch (error) {
      enqueueSnackbar("Chyba pri sťahovaní súboru", { variant: "error" });
    }
  };


  return (
    <>
    <Dialog open={true} fullWidth maxWidth="xl" onClose={handleClose}>
      <DialogTitle>{`${type === CustomerType.BUYER ? "Odberateľ" : "Dodávateľ"} - ${name}`}</DialogTitle>
      <DialogContent>
        <DataGrid
          id={`${type === CustomerType.BUYER ? "vydajky" : "prijemky"}-${name}`}
          title={`${type === CustomerType.BUYER ? "Výdajky" : "Príjemky"}`}
          loading={loading}
          fullHeight
          topSpacing={144}
          rows={data?.warehouseDocumentsByCustomerId ?? []}
          columns={columns}
          onRowClick={(params) => setSelectedRow(params.row as IDocument)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="small" onClick={handleClose}>
          Zatvoriť
        </Button>
      </DialogActions>
    </Dialog>
    {selectedRow && <DetailModal handleClose={() => setSelectedRow(null)} data={selectedRow} />}
    </>
  );
};

export default HistoryModal;
