import { useEffect, useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  IconButton,
  MenuItem,
  Stack,
  Select as MUISelect,
  Typography,
  lighten,
  styled,
  useTheme,
  darken,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import { DatePicker } from "@mui/x-date-pickers";
import BackdropLoading from "components/elements/BackdropLoading";
import Select from "components/elements/Select";
import SnackbarCloseButton from "components/elements/SnackbarCloseButton";
import { useUserDataContext } from "context/UserDataContext";
import {
  useAPIConstantsByCode,
  useAPICurrencies,
  useAPIGetArticlesByWarehouse,
  useAPIUpdateDocument,
  useAPIWarehouses,
  useAPIArticles,
} from "hooks/useAPI";
import { enqueueSnackbar } from "notistack";
import { IArticle, ICurrency, ICustomerBasic, IDocument, IDocumentItems } from "types";
import { CustomerType, WDocumentType } from "types/enums";

import CompanyItem from "../AddModal/CompanyItem";
import CustomerSection from "../AddModal/CustomerSection";
import ItemInput from "../TransferEditModal/ItemInput";

interface IProps {
  handleClose: () => void;
  refetch: () => void;
  data: IDocument;
}

const DOC_DATA = {
  [WDocumentType.IN]: {
    title: "Upraviť skladovú príjemku",
    customer: "Dodávatel",
  },
  [WDocumentType.OUT]: {
    title: "Upraviť skladovú výdajku",
    customer: "Odberateľ",
  },
};

const SectionBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: theme.palette.mode === "light" ? lighten(theme.palette.primary.main, 0.8) : darken(theme.palette.primary.main, 0.8),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  alignContent: "center",
}));

const getCurrencyName = (data: ICurrency[], currencyId?: number) => {
  if (!currencyId) return undefined;

  const currency = data.find((currency) => currency.id === currencyId);
  return currency?.description ?? currency?.name ?? undefined;
};

const EditModal = ({ handleClose, refetch, data }: IProps) => {
  const theme = useTheme();
  const type = data.type === WDocumentType.IN ? WDocumentType.IN : WDocumentType.OUT;

  const { userData } = useUserDataContext();
  const { data: warehousesData } = useAPIWarehouses();
  const { data: currenciesData } = useAPICurrencies();
  const { data: companyInfo } = useAPIConstantsByCode("COMPANY_INFO");
  const [getArticlesByWarehouse] = useAPIGetArticlesByWarehouse();
  const { data: articleData } = useAPIArticles();
  const [updateDocument] = useAPIUpdateDocument();

  const [loading, setLoading] = useState(false);
  const [isInit, setIsInit] = useState(false);
  const [articles, setArticles] = useState<IArticle[]>([]);
  const [articlesLoading, setArticlesLoading] = useState<boolean>(true);

  const [createdAt, setCreatedAt] = useState<Date | null>(new Date(data.createdAt));
  const [note, setNote] = useState<string>(data.note ?? "");
  const [currencyId, setCurrencyId] = useState<number>(data.currencyId);
  const [items, setItems] = useState<IDocumentItems[]>(data.items);
  const [customer, setCustomer] = useState<ICustomerBasic | undefined>();
  const [customerId, setCustomerId] = useState<number | undefined>(data.customerId);
  const [warehouseId, setWarehouseId] = useState<number | undefined>(data.warehouseId);
  const [comment, setComment] = useState<string>(data.comment ?? "");

  const currency = getCurrencyName(currenciesData?.currencies ?? [], currencyId);
  const company = companyInfo ? JSON.parse(companyInfo.constantByCode.value) : undefined;

  const fetchArticlesByWarehouse = async () => {
    if (!warehouseId) return;

    try {
      setArticlesLoading(true);
      const { data: articleData } = await getArticlesByWarehouse({ variables: { warehouseId: warehouseId } });
      if (articleData && articleData.articlesByWarehouse.length) {
        if (warehouseId === data.warehouseId) {
          const newArticles = articleData.articlesByWarehouse.map((article) => {
            const item = data.items.find((i) => i.articleId === article.id);
            if (item) return { ...article, quantity: article.quantity + item.quantity };
            return article;
          });
          setArticles(newArticles);
        } else setArticles(articleData.articlesByWarehouse);
      } else setArticles([]);
      setArticlesLoading(false);
    } catch (e) {
      enqueueSnackbar("Nepodarilo sa načítať položky", { variant: "error" });
    }
  };

  useEffect(() => {
    if (warehouseId && type === WDocumentType.OUT) fetchArticlesByWarehouse();
  }, [warehouseId, type]);

  useEffect(() => {
    if (articleData && articleData.articles && type === WDocumentType.IN) {
      setArticles(articleData.articles);
    }
  }, [articleData, type]);

  useEffect(() => {
    if (currenciesData && currenciesData.currencies.length > 0) {
      setCurrencyId(currenciesData.currencies[0].id);
    }
  }, [currenciesData]);

  useEffect(() => {
    if (warehouseId && items.length) setIsInit(true);
  }, [warehouseId, items]);

  useEffect(() => {
    if (warehouseId && type === WDocumentType.OUT && isInit) setItems([]);
  }, [warehouseId]);

  const handleUpdate = async () => {
    if (!createdAt || !currencyId || !warehouseId || !items.length || !userData?.id) return;
    setLoading(true);
    const price = items.reduce((acc, item) => acc + item.price * item.quantity, 0);
    try {
      await updateDocument({
        variables: {
          updateWarehouseDocumentInput: {
            id: data.id,
            createdAt: createdAt.toISOString(),
            note,
            currencyId,
            warehouseId,
            items,
            type,
            userId: userData?.id,
            customerId,
            customer,
            price,
            comment,
          },
        },
      });
      handleClose();
      refetch();
      enqueueSnackbar(`${DOC_DATA[type].title} sa úspešne podarilo`, { variant: "success" });
    } catch (e: any) {
      if (e.message === "Insufficient stock") {
        enqueueSnackbar(
          `${DOC_DATA[type].title} sa nepodarilo. Jeden alebo viac produktov by sa mohlo dostať do záporných hodnôt!`,
          {
            variant: "error",
            persist: true,
            action: SnackbarCloseButton,
          }
        );
      } else {
        enqueueSnackbar(`${DOC_DATA[type].title} sa nepodarilo`, { variant: "error" });
      }
    } finally {
      setLoading(false);
    }
  };

  const getTotalPrice = () => {
    const totalPrice = items.reduce((acc, item) => acc + item.price * item.quantity, 0);
    const value = totalPrice
      .toFixed(2)
      .replace(".", ",")
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    if (currency) return `${value} ${currency}`;
    return value;
  };

  const isFulfilledCustomer = () =>
    customerId
    || (customer && customer.name && customer.address && customer.city && customer.postalCode && customer.country);

  const isPossibleToCreate = () => createdAt && currencyId && warehouseId && items.length > 0 && items.every((item) => item.articleId !== -1) && isFulfilledCustomer();

  const handleAddItem = () => setItems((prev) => [...prev, { price: 0, quantity: 1, articleId: -1 }]);
  const handleRemoveItem = (index: number) => setItems((prev) => prev.filter((_, i) => i !== index));
  const handleItemChange = (index: number, data: IDocumentItems) => {
    setItems((prev) => {
      const newItems = [...prev];
      newItems[index] = data;
      return newItems;
    });
  };

  return (
    <Dialog open={true} fullWidth maxWidth="md">
      <DialogTitle>{DOC_DATA[type].title}</DialogTitle>
      <DialogContent>
        <Grid sx={{ mb: 1 }} container spacing={2}>
          <Grid xs={12} sm={6}>
            <CompanyItem {...company} loading={!companyInfo} />
          </Grid>
          <Grid xs={12} sm={6}>
            <Stack pt={1} direction={{ xs: "column", md: "row" }} spacing={1}>
              <Box width={{ xs: "100%", md: "50%" }}>
                <Select
                  size="small"
                  label="Sklad *"
                  fullWidth
                  value={warehouseId ?? ""}
                  onChange={(e) => setWarehouseId(e.target.value as number)}
                  disabled={warehousesData?.warehouses.length === 0 || !warehousesData?.warehouses}>
                  {warehousesData?.warehouses.map((warehouse) => (
                    <MenuItem key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box width={{ xs: "100%", md: "50%" }}>
                <DatePicker
                  label="Vystavená dňa *"
                  sx={{ width: "100%" }}
                  value={createdAt}
                  onChange={(newValue) => setCreatedAt(newValue)}
                  slotProps={{ textField: { size: "small" } }}
                  timezone="Europe/Bratislava"
                />
              </Box>
            </Stack>
          </Grid>
        </Grid>
        <Grid sx={{ my: 1 }} container spacing={2}>
          <Grid xs={12} sm={6}>
            <SectionBox width={"100%"}>
              <Typography variant="subtitle2" fontWeight={400}>
                Mena
              </Typography>
              <MUISelect
                sx={{ minWidth: "70px", backgroundColor: theme.palette.background.paper }}
                size="small"
                value={currencyId ?? ""}
                disabled={currenciesData?.currencies.length === 0 || !currenciesData?.currencies}
                onChange={(e) => setCurrencyId(e.target.value as number)}>
                {currenciesData?.currencies.map((currency) => (
                  <MenuItem key={currency.id} value={currency.id}>
                    {currency.name}
                  </MenuItem>
                ))}
              </MUISelect>
            </SectionBox>
          </Grid>
          <Grid xs={12} sm={6} height={"auto"}>
            <SectionBox width={"100%"} height={"100%"}>
              <Typography variant="subtitle2">{DOC_DATA[type].customer}</Typography>
            </SectionBox>
          </Grid>
        </Grid>
        <Grid sx={{ mb: 1 }} container spacing={2}>
          <Grid xs={12} sm={6} smOffset={6}>
            <CustomerSection
              customerId={customerId}
              customer={customer}
              onChangeCustomer={(data) => setCustomer(data)}
              onChangeCustomerId={(id) => setCustomerId(id)}
              type={type === WDocumentType.OUT ? CustomerType.BUYER : CustomerType.SUPPLIER}
            />
          </Grid>
        </Grid>
        <TextField sx={{ my: 1 }} size="small" fullWidth value={note} onChange={(e) => setNote(e.target.value)} />
        <Stack my={1}>
          <SectionBox>
            <Typography variant="subtitle2" fontWeight={400}>
              Položky
            </Typography>
            <IconButton size="small" onClick={handleAddItem}>
              <AddCircleIcon fontSize="small" />
            </IconButton>
          </SectionBox>
          <Stack pt={1}>
            {items.map((item, index) => (
              <ItemInput
                key={`item-${index}`}
                articles={articles}
                data={item}
                index={index}
                onChange={handleItemChange}
                onDelete={handleRemoveItem}
                warehouseId={type === WDocumentType.OUT ? warehouseId ?? -1 : undefined}
                selectedItems={items.map((item) => item.articleId ?? -1)}
                articlesLoading={articlesLoading}
              />
            ))}
          </Stack>
        </Stack>
        <Box my={1} display={"flex"} justifyContent={"end"}>
          <SectionBox width={{ xs: "100%", sm: "50%" }}>
            <Typography variant="subtitle2">Celkom k úhrade</Typography>
            <Typography variant="subtitle2">{getTotalPrice()}</Typography>
          </SectionBox>
        </Box>
        <TextField sx={{ my: 1 }} size="small" label="Komentár" fullWidth value={comment} onChange={(e) => setComment(e.target.value)} />
        <Stack direction="column" sx={{ pt: 1 }}>
          <Typography sx={{ pb: 1 }} variant="subtitle1" fontWeight={700}>
            Vystavil:
          </Typography>
          <Typography
            variant="subtitle2"
            color={"text.secondary"}>{`${userData?.name} ${userData?.surname}`}</Typography>
          <Typography variant="subtitle2" color={"text.secondary"}>
            {userData?.email}
          </Typography>
        </Stack>
        <BackdropLoading loading={loading} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="small" onClick={handleClose}>
          Zrušiť
        </Button>
        <Button variant="contained" size="small" disabled={!isPossibleToCreate()} onClick={handleUpdate}>
          Uložiť
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
