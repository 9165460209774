import React from "react";

import { ApolloProvider } from "@apollo/client";
import { CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import client from "config/ApolloClient";
import MUITheme from "config/MUITheme";
import { ModalProvider } from "context/ModalContext";
import { UserDataProvider } from "context/UserDataContext";
import { sk } from "date-fns/locale";
import { SnackbarProvider } from "notistack";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <UserDataProvider>
      <BrowserRouter>
        <ApolloProvider client={client}>
          <MUITheme>
            <SnackbarProvider
              maxSnack={3}
              preventDuplicate
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}>
              <CssBaseline />
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sk}>
                <ModalProvider>
                  <App />
                </ModalProvider>
              </LocalizationProvider>
            </SnackbarProvider>
          </MUITheme>
        </ApolloProvider>
      </BrowserRouter>
    </UserDataProvider>
  </React.StrictMode>
);
