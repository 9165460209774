import React, { useEffect } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import { Alert, Autocomplete, Box, IconButton, Skeleton, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useUserDataContext } from "context/UserDataContext";
import { IArticle, IDocumentItems } from "types";

interface IProps {
  data: IDocumentItems;
  index: number;
  onChange: (index: number, data: IDocumentItems) => void;
  onDelete: (index: number) => void;
  warehouseId?: number;
  selectedItems?: number[];
  articles: IArticle[];
  articlesLoading?: boolean;
}

interface IOption {
  label: string;
  id: number;
}

const ItemInput: React.FC<IProps> = ({ data, index, onChange, onDelete, warehouseId, selectedItems, articles, articlesLoading }) => {
  const { isAdmin } = useUserDataContext();
  const article = articles.find((article) => article.id === data.articleId);

  useEffect(() => {
    if (article && data.price === 0) {
      onChange(index, { ...data, price: article.priceVat });
    }
  }, [article]);

  const handleNameChange = (_event: any, newValue: IOption | null) => {
    const newArticle = articles.find((article) => article.id === newValue?.id);

    if (newArticle) onChange(index, { ...data, articleId: newArticle.id, price: newArticle.priceVat, quantity: 1 });
    if (newValue === null) onChange(index, { ...data, articleId: -1, price: 0, quantity: 1 });
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) onChange(index, { ...data, quantity: Number(e.target.value) });
  };

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) onChange(index, { ...data, price: Number(e.target.value) });
  };

  const getTotalPrice = () => {
    const totalPrice = data.quantity * data.price;
    const value = totalPrice.toFixed(2).replace(".", ",");

    return value;
  };

  const getQuantityMax = () => {
    if (warehouseId) {
      if (article) return article.quantity;
      else return 1;
    }

    if (article) return undefined;
    else return 1;
  };

  const getOption = (option: IArticle) => {
    return { label: `[${option.id}] ${option.name} - ${option.category.name}/${option.subcategory.name}`, id: option.id };
  };

  const getOptions = () => {
    if (warehouseId) {
      return (
        articles
          ?.filter((option) => !selectedItems?.some((el) => el === option.id) && option.quantity > 0)
          .map((option) => getOption(option)) || []
      );
    }

    return (articles?.filter((option) => !selectedItems?.some((el) => el === option.id)).map((option) => getOption(option)) || []);
  };

  if (!article && !articlesLoading && data.articleId !== -1) return <Alert sx={{mb: 1}} severity="warning">{`Položka ${data.articleId} nebola nájdená!`}</Alert>;

  if (!article && data.articleId !== -1) return <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />;

  return (
    <Grid container spacing={1} pb={1}>
      <Grid xs={12} sm={5}>
        <Autocomplete
          size="small"
          value={article ? getOption(article) : null}
          onChange={handleNameChange}
          options={getOptions() || []}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Názov"
              helperText={warehouseId && article?.quantity ? `Na sklade: ${article?.quantity}` : ""}
            />
          )}
        />
      </Grid>
      <Grid xs={4} sm={1.2}>
        <TextField
          size="small"
          required
          label={"Množstvo"}
          type="number"
          value={data.quantity}
          onChange={handleQuantityChange}
          inputProps={{ max: getQuantityMax(), min: 1, step: 1 }}
        />
      </Grid>
      <Grid xs={3.5} sm={1}>
        <TextField size="small" label="MJ" value={article?.unit.name ?? ""} disabled />
      </Grid>
      <Grid xs={4.5} sm={2}>
        <TextField size="small" required label={"JC"} type="number" value={data.price} onChange={handlePriceChange} disabled={!isAdmin} />
      </Grid>
      <Grid xs={10} sm={2}>
        <TextField fullWidth size="small" label="Spolu" value={getTotalPrice()} disabled />
      </Grid>
      <Grid xs={2} sm={0.8} display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <IconButton size="small" onClick={() => onDelete(index)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default ItemInput;
