import { useState } from "react";

import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import BackdropLoading from "components/elements/BackdropLoading";
import { useAPIUpdateMinStock } from "hooks/useAPI";
import { enqueueSnackbar } from "notistack";
import { IArticleInput } from "types";

interface IProps extends Partial<IArticleInput> {
  handleClose: () => void;
  refetch: () => void;
  warehouseId: string;
  articleId: number;
  minStock: number | null;
}

const MinStockModal = ({ handleClose, refetch, minStock: minStockProps, warehouseId, articleId }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [minStock, setMinStock] = useState<number | null>(minStockProps);

  const [updateMinStock] = useAPIUpdateMinStock();

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await updateMinStock({
        variables: {
          warehouseId: Number(warehouseId),
          articleId: Number(articleId),
          minStock: Number(minStock),
        },
      });
      handleClose();
      refetch();
      enqueueSnackbar("Minimálne množstvo bolo úspešne nastavené", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Minimálne množstvo sa nepodarilo nastaviť", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={true} fullWidth maxWidth="xs">
      <DialogTitle>{"Nastavenie minimálného množstva"}</DialogTitle>
      <DialogContent>
        <Box pt={1}>
          <TextField
            fullWidth
            label="Minimálne množstvo"
            type="number"
            value={minStock}
            onChange={(e) => setMinStock(e.target.value ? Number(e.target.value) : null)}
          />
        </Box>
        <BackdropLoading loading={loading} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="small" onClick={handleClose}>
          Zrušiť
        </Button>
        <Button variant="contained" size="small" onClick={handleSubmit}>
          {"Uložiť"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MinStockModal;
