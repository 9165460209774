import { Box, Stack, Typography, lighten, styled, darken, Skeleton } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Unstable_Grid2";
import ReadOnlyTextField from "components/elements/ReadOnlyTextField";
import { useAPIConstantsByCode, useAPIArticles, useAPICustomersByType } from "hooks/useAPI";
import { IDocument } from "types";
import { CustomerType, WDocumentType } from "types/enums";
import { formatDate } from "utils";

import Item from "./Item";
import CompanyItem from "../AddModal/CompanyItem";

interface IProps {
  handleClose: () => void;
  data: IDocument;
}

const DOC_DATA = {
  [WDocumentType.IN]: {
    title: "Detail skladovej príjemky",
    customer: "Dodávatel",
  },
  [WDocumentType.OUT]: {
    title: "Detail skladovej výdajky",
    customer: "Odberateľ",
  },
  [WDocumentType.TRANSFER]: {
    title: "Detail skladového presunu",
    customer: "Na sklad",
  },
};

const SectionBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor:
    theme.palette.mode === "light" ? lighten(theme.palette.primary.main, 0.8) : darken(theme.palette.primary.main, 0.8),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  alignContent: "center",
}));

const DetailModal = ({ handleClose, data }: IProps) => {
  const { data: companyInfo } = useAPIConstantsByCode("COMPANY_INFO");
  const { data: articleData } = useAPIArticles();
  const { data: customersData } = useAPICustomersByType(
    data.type === WDocumentType.OUT ? CustomerType.BUYER : CustomerType.SUPPLIER
  );

  const selectedCustomer = customersData?.customersByType.find((customer) => customer.id === data.customerId);
  const company = companyInfo ? JSON.parse(companyInfo.constantByCode.value) : undefined;
  const isTransfer = data.type === WDocumentType.TRANSFER;

  const getTotalPrice = () => {
    const value = data.price
      .toFixed(2)
      .replace(".", ",")
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    return `${value} ${data.currency.description ?? data.currency.name}`;
  };

  return (
    <Dialog open={true} fullWidth maxWidth="md">
      <DialogTitle>{`${DOC_DATA[data.type].title} - ${data.number}`}</DialogTitle>
      <DialogContent>
        <Grid sx={{ mb: 1 }} container spacing={2}>
          {isTransfer ? (
            <Grid xs={12} sm={6}>
              <Box pt={1}>
                <ReadOnlyTextField size="small" label="Zo skladu" value={data.sourceWarehouse?.name} />
              </Box>
            </Grid>
          ) : (
            <Grid xs={12} sm={6}>
              <CompanyItem {...company} loading={!companyInfo} />
            </Grid>
          )}
          <Grid xs={12} sm={6}>
            {isTransfer ? (
              <Box pt={1}>
                <ReadOnlyTextField size="small" label="Vystavená dňa" value={formatDate(data.createdAt)} />
              </Box>
            ) : (
              <Stack pt={1} direction={{ xs: "column", md: "row" }} spacing={1}>
                <Box width={{ xs: "100%", md: "50%" }}>
                  <ReadOnlyTextField size="small" label="Sklad" value={data.warehouse.name} />
                </Box>
                <Box width={{ xs: "100%", md: "50%" }}>
                  <ReadOnlyTextField size="small" label="Vystavená dňa" value={formatDate(data.createdAt)} />
                </Box>
              </Stack>
            )}
          </Grid>
        </Grid>
        <Grid sx={{ my: 1 }} container spacing={2}>
          <Grid xs={12} sm={6}>
            <SectionBox width={"100%"}>
              <Typography variant="subtitle2" fontWeight={400}>
                Mena
              </Typography>
              <Typography variant="subtitle1" fontWeight={500}>
                {data.currency.name ?? ""}
              </Typography>
            </SectionBox>
          </Grid>
          <Grid xs={12} sm={6} height={"auto"}>
            <SectionBox width={"100%"} height={"100%"}>
              <Typography variant="subtitle2">{DOC_DATA[data.type].customer}</Typography>
            </SectionBox>
          </Grid>
        </Grid>
        <Grid sx={{ mb: 1 }} container spacing={2}>
          <Grid xs={12} sm={6} smOffset={6}>
            {isTransfer ? (
              <ReadOnlyTextField size="small" value={data.warehouse.name} />
            ) : (
              <CompanyItem
                stackProps={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                name={selectedCustomer?.name ?? ""}
                address={selectedCustomer?.address ?? ""}
                city={selectedCustomer?.city ?? ""}
                postalCode={selectedCustomer?.postalCode ?? ""}
                country={selectedCustomer?.country ?? ""}
                loading={!selectedCustomer}
              />
            )}
          </Grid>
        </Grid>
        <ReadOnlyTextField size="small" label="Poznámka" value={data.note} fullWidth />
        <Stack my={1}>
          <SectionBox>
            <Typography variant="subtitle2" fontWeight={400}>
              Položky
            </Typography>
          </SectionBox>
          <Stack pt={2}>
            {data.items.map((item, index) => {
              if (!articleData) return <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />

              const article = articleData?.articles.find((article) => article.id === item.articleId);
              return (
                <Item
                  key={`item-${index}`}
                  name={article?.name ?? ""}
                  quantity={item.quantity}
                  price={item.price}
                  unit={article?.unit?.name ?? ""}
                />
              );
            })}
          </Stack>
        </Stack>
        <Box my={1} display={"flex"} justifyContent={"end"}>
          <SectionBox width={{ xs: "100%", sm: "50%" }}>
            <Typography variant="subtitle2">Celkom k úhrade</Typography>
            <Typography variant="subtitle2">{getTotalPrice()}</Typography>
          </SectionBox>
        </Box>
        {data.comment && <ReadOnlyTextField size="small" label="Komentár" value={data.comment} fullWidth />}
        <Stack direction="column" sx={{ pt: 1 }}>
          <Typography sx={{ pb: 1 }} variant="subtitle1" fontWeight={700}>
            Vystavil:
          </Typography>
          <Typography
            variant="subtitle2"
            color={"text.secondary"}>{`${data?.user?.name} ${data?.user?.surname}`}</Typography>
          <Typography variant="subtitle2" color={"text.secondary"}>
            {data?.user?.email}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="small" onClick={handleClose}>
          Zatvoriť
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DetailModal;
