import React, { ChangeEvent } from "react";

import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import { darken, lighten, styled, useTheme } from "@mui/material/styles";
import {
  DataGrid as MUIDataGrid,
  DataGridProps,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";

interface CustomToolbarProps {
  title?: string;
  actions?: React.ReactNode;
  id?: string;
  topSpacing?: number;
  onImport?: (file: File) => void;
}

interface IProps extends DataGridProps, CustomToolbarProps {
  fullHeight?: boolean;
}

const getBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);
const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);
const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);
const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(MUIDataGrid)(({ theme }) => ({
  "& .row--warning": {
    backgroundColor: getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(theme.palette.warning.main, theme.palette.mode),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(theme.palette.warning.main, theme.palette.mode),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(theme.palette.warning.main, theme.palette.mode),
      },
    },
  },
  "& .row--error": {
    backgroundColor: getBackgroundColor(theme.palette.error.main, theme.palette.mode),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(theme.palette.error.main, theme.palette.mode),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(theme.palette.error.main, theme.palette.mode),
      },
    },
  },
  "& .row--unread": {
    backgroundColor: getBackgroundColor(theme.palette.grey[500], theme.palette.mode),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(theme.palette.grey[500], theme.palette.mode),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(theme.palette.grey[500], theme.palette.mode),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(theme.palette.grey[500], theme.palette.mode),
      },
    },
  },
  "& .row--success": {
    backgroundColor: getBackgroundColor(theme.palette.success.main, theme.palette.mode),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(theme.palette.success.main, theme.palette.mode),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(theme.palette.success.main, theme.palette.mode),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(theme.palette.success.main, theme.palette.mode),
      },
    },
  },
}));

const CustomToolbar = ({ title, actions, id, onImport }: CustomToolbarProps) => {
  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];

    if (file.name.split(".").pop() !== "csv") {
      alert("Invalid file format. Please upload a CSV file.");
      return;
    }

    if (onImport) onImport(file);
  };

  return (
    <GridToolbarContainer>
      <Stack spacing={1} flexGrow={1} direction={"row"} alignItems={"center"}>
        <Typography sx={{ ml: 1 }} variant="h6">
          {title}
        </Typography>
        {actions}
      </Stack>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          delimiter: ";",
          utf8WithBom: true,
          fileName: id,
        }}
      />
      {onImport && (
        <Button startIcon={<UploadFileIcon />} variant="text" component="label" size="small">
          Import
          <input type="file" accept=".csv" hidden onChange={handleFileUpload} />
        </Button>
      )}
    </GridToolbarContainer>
  );
};

const DataGrid: React.FC<IProps> = ({ title, fullHeight, actions, id, topSpacing = 48, onImport, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledDataGrid
      slots={{ toolbar: CustomToolbar }}
      slotProps={{ toolbar: { title, actions, id, onImport } }}
      sx={{
        backgroundColor: theme.palette.background.paper,
        height: fullHeight ? `calc(100vh - ${isMobile ? "56px" : "64px"} - ${topSpacing}px)` : undefined,
        "--DataGrid-containerBackground": theme.palette.background.paper,
      }}
      {...props}
    />
  );
};

export default DataGrid;
