import React from "react";

import { FormControl, InputLabel, Select as MUISelect, SelectProps } from "@mui/material";

type IProps = {
  label: string;
} & SelectProps;

const Select: React.FC<IProps> = ({ label, size, required, ...props }) => {
  return (
    <FormControl fullWidth required={required} size={size}>
      <InputLabel>{label}</InputLabel>
      <MUISelect label={label} {...props} />
    </FormControl>
  );
};

export default Select;
