import React, { useMemo, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import { GridActionsCellItem, GridColDef, GridColType, GridRowParams } from "@mui/x-data-grid";
import DataGrid from "components/elements/DataGrid";
import Switch from "components/elements/Switch";
import { useModalContext } from "context/ModalContext";
import { useUserDataContext } from "context/UserDataContext";
import {
  useAPIArticlesByWarehouse,
  useAPICategories,
  useAPICurrencies,
  useAPIRemoveStock,
  useAPISubcategories,
  useAPIUnits,
  useAPIWarehouse,
} from "hooks/useAPI";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { IArticle } from "types";

import MinStockModal from "./MinStockModal";

const Warehouse: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { showModal, closeModal } = useModalContext();
  const { isAdmin } = useUserDataContext();
  const confirm = useConfirm();
  const [onlyLowStock, setOnlyLowStock] = useState(false);
  const { loading, data, refetch } = useAPIArticlesByWarehouse(Number(id));
  const { data: warehouseData } = useAPIWarehouse(Number(id));
  const { data: categoriesData } = useAPICategories();
  const { data: subcategoriesData } = useAPISubcategories();
  const { data: currenciesData } = useAPICurrencies();
  const { data: unitsData } = useAPIUnits();
  const [removeStock] = useAPIRemoveStock();

  const columns: GridColDef<IArticle>[] = useMemo(
    () => [
      { field: "id", headerName: "Kód", width: 70, hide: true },
      { field: "name", headerName: "Názov", flex: 1 },
      {
        field: "category",
        headerName: "Kategória",
        width: 75,
        type: "singleSelect",
        valueOptions: categoriesData?.categories.map((cat) => ({ value: cat.name, label: cat.name })) ?? [],
        valueGetter: (_value, row) => row.category.name ?? "",
      },
      {
        field: "subcategory",
        headerName: "Podkategória",
        width: 100,
        type: "singleSelect",
        valueOptions: subcategoriesData?.subcategories.map((sub) => ({ value: sub.name, label: sub.name })) ?? [],
        valueGetter: (_value, row) => row.subcategory.name ?? "",
      },
      { field: "quantity", headerName: "Množstvo", type: "number", width: 90 },
      {
        field: "unit",
        headerName: "MJ",
        width: 70,
        type: "singleSelect",
        valueOptions: unitsData?.units.map((unit) => ({ value: unit.name, label: unit.name })) ?? [],
        valueGetter: (_value, row) => row.unit.name ?? "",
      },
      {
        field: "minStock",
        headerName: "Min. množstvo",
        type: "number",
        width: 110,
        valueGetter: (_value, row) => (row.minStock ? row.minStock : ""),
      },
      { field: "price", headerName: "Cena bez DPH", type: "number", width: 105 },
      { field: "priceVat", headerName: "Cena s DPH", type: "number", width: 90 },
      {
        field: "currency",
        headerName: "Mena",
        width: 70,
        type: "singleSelect",
        valueOptions: currenciesData?.currencies.map((cur) => ({ value: cur.name, label: cur.name })) ?? [],
        valueGetter: (_value, row) => row.currency.name ?? "",
      },
      { field: "manufacturer", headerName: "Výrobca", width: 70 },
      { field: "note", headerName: "Poznámka", width: 160 },
      ...(isAdmin
        ? [
            {
              field: "actions",
              type: "actions" as GridColType,
              width: 80,
              getActions: (params: GridRowParams<IArticle>) => [
                <GridActionsCellItem
                  icon={<SettingsIcon />}
                  label="Edit"
                  onClick={() => handleEditMinStock(params.row)}
                />,
                <GridActionsCellItem
                  disabled={params.row.quantity !== 0}
                  icon={<DeleteIcon />}
                  label="Delete"
                  onClick={() => handleDelete(params.row)}
                />,
              ],
            },
          ]
        : []),
    ],
    [categoriesData, subcategoriesData, currenciesData, unitsData, id, isAdmin]
  );

  const handleDelete = (row: IArticle) => {
    confirm({
      title: "Naozaj chcete vymazať záznam o tovare z tohto skladu?",
      description: `Záznam o tovare ${row.name} bude vymazaný. Táto akcia je nevratná.`,
    })
      .then(async () => {
        try {
          await removeStock({ variables: { articleId: row.id, warehouseId: Number(id) } });
          refetch();
          enqueueSnackbar("Záznam o tovare bol vymazaný", { variant: "success" });
        } catch (e) {
          enqueueSnackbar("Nepodarilo sa vymazať záznam o tovare", { variant: "error" });
        }
      })
      .catch(() => {
        /* ... */
      });
  };

  const handleEditMinStock = (row: IArticle) => {
    if (!id) return;

    showModal(
      <MinStockModal
        handleClose={closeModal}
        refetch={refetch}
        warehouseId={id}
        articleId={row.id}
        minStock={row.minStock}
      />
    );
  };

  const getRowClassName = (params: { row: IArticle }) => {
    if (params.row.minStock && params.row.quantity === 0) return `row--error`;
    if (params.row.minStock && params.row.quantity <= params.row.minStock && id !== "2") return `row--warning`;

    return "";
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnlyLowStock(event.target.checked);
  };

  const getData = () => {
    if (!data) return [];

    return onlyLowStock
      ? data.articlesByWarehouse.filter((article) => article.minStock && article.quantity <= article.minStock)
      : data.articlesByWarehouse;
  };

  const getTableId = () => {
    const warehouseId = warehouseData?.warehouse.name?.toLowerCase().replace(/ /g, "-") ?? id;
    return `sklad-${warehouseId}`;
  };

  return (
    <DataGrid
      key={`warehouse-${id}`}
      id={getTableId()}
      title={warehouseData?.warehouse.name ?? "---"}
      actions={<Switch checked={onlyLowStock} onChange={handleChange} label="Zobraz malé zásoby" />}
      fullHeight
      loading={loading}
      rows={getData()}
      columns={columns}
      getCellClassName={getRowClassName}
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
    />
  );
};

export default Warehouse;
