import React, { useState, useContext, createContext } from "react";

import { IModalContext } from "types";

const ModalContext = createContext<IModalContext>({
  closeModal: () => null,
  showModal: () => null,
});

interface IProps {
  children: React.ReactNode;
}

export const ModalProvider = ({ children }: IProps) => {
  const [modal, setModal] = useState<React.ReactNode>();

  return (
    <ModalContext.Provider
      value={{
        closeModal: () => setModal(undefined),
        showModal: (modal) => setModal(modal),
      }}>
      {children}
      {modal}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => useContext(ModalContext);
