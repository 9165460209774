import { Box, CircularProgress } from "@mui/material";
import useAppInit from "hooks/useAppInit";
import AppRoutes from "routes";

const App = () => {
  const { isReadyToRender } = useAppInit();

  if (!isReadyToRender) {
    return (
      <Box display={"flex"} justifyContent={"center"} height={"100vh"} alignItems={"center"}>
        <CircularProgress />
      </Box>
    );
  }

  return <AppRoutes />;
};

export default App;
