import React, { useMemo } from "react";

import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import { GridActionsCellItem, GridColDef, GridColType, GridRowParams } from "@mui/x-data-grid";
import DataGrid from "components/elements/DataGrid";
import { useModalContext } from "context/ModalContext";
import { useUserDataContext } from "context/UserDataContext";
import { useAPIRemoveWarehouse, useAPIWarehouses } from "hooks/useAPI";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar } from "notistack";
import { IWarehouse } from "types";

import AddModal from "./AddModal";

const Warehouses: React.FC = () => {
  const { showModal, closeModal } = useModalContext();
  const { loading, data, refetch } = useAPIWarehouses();
  const { isAdmin } = useUserDataContext();
  const [removeWarehouse] = useAPIRemoveWarehouse();
  const confirm = useConfirm();

  const columns: GridColDef<IWarehouse>[] = useMemo(
    () => [
      { field: "id", headerName: "ID", flex: 1 },
      { field: "name", headerName: "Názov", flex: 1 },
      { field: "description", headerName: "Popis", flex: 1 },
      ...(isAdmin
        ? [
            {
              field: "actions",
              type: "actions" as GridColType,
              width: 80,
              getActions: (params: GridRowParams<IWarehouse>) => [
                <GridActionsCellItem icon={<CreateIcon />} label="Edit" onClick={() => handleEdit(params.row)} />,
                <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => handleDelete(params.row)} />,
              ],
            },
          ]
        : []),
    ],
    [isAdmin]
  );

  const handleCreate = () => {
    showModal(<AddModal handleClose={closeModal} refetch={refetch} />);
  };

  const handleEdit = (row: IWarehouse) => {
    showModal(<AddModal handleClose={closeModal} refetch={refetch} {...row} />);
  };

  const handleDelete = (row: IWarehouse) => {
    confirm({
      title: "Naozaj chcete vymazať tento sklad?",
      description: `${row.name} bude vymazaný. Táto akcia je nevratná.`,
    })
      .then(async () => {
        try {
          await removeWarehouse({ variables: { removeWarehouseId: row.id } });
          refetch();
          enqueueSnackbar("Sklad bol vymazaný", { variant: "success" });
        } catch (e) {
          enqueueSnackbar("Sklad sa nepodarilo vymazať", { variant: "error" });
        }
      })
      .catch(() => {
        /* ... */
      });
  };

  return (
    <>
      <DataGrid
        id="sklady"
        title={"Sklady"}
        actions={
          isAdmin ? (
            <Button variant="contained" size="small" onClick={handleCreate} endIcon={<AddIcon />}>
              Pridať
            </Button>
          ) : undefined
        }
        fullHeight
        loading={loading}
        rows={data?.warehouses ?? []}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: "id",
                sort: "asc",
              },
            ],
          },
        }}
      />
    </>
  );
};

export default Warehouses;
