import React from "react";

import { Backdrop, CircularProgress } from "@mui/material";

interface IProps {
  loading: boolean;
}

const BackdropLoading: React.FC<IProps> = ({ loading }: IProps) => {
  return (
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default BackdropLoading;
