import React, { useMemo, useState } from "react";

import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { GridCellParams, GridColDef, GridRowParams } from "@mui/x-data-grid";
import DataGrid from "components/elements/DataGrid";
import { useModalContext } from "context/ModalContext";
import { useAPICustomersWithAnnualInspection } from "hooks/useAPI";
import { ICustomersWithAnnualInspection } from "types";
import { formatDate } from "utils";

import DetailModal from "../Documents/DetailModal";

const Inspections: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState<number>(currentYear);
  const { showModal, closeModal } = useModalContext();
  const { loading, data } = useAPICustomersWithAnnualInspection(selectedYear);

  const columns: GridColDef<ICustomersWithAnnualInspection>[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Meno zákazníka",
        flex: 1,
      },
      { field: "address", headerName: "Adresa", flex: 1 },
      { field: "city", headerName: "Mesto", flex: 1 },
      { field: "note", headerName: "Poznámka", width: 80 },
      {
        field: "warehouseDocuments",
        headerName: "Dátum prehliadky",
        width: 180,
        type: "date",
        valueGetter: (_value, row) => row.warehouseDocuments?.[0]?.createdAt,
        valueFormatter: (value) => (value ? formatDate(value) : "---"),
      },
    ],
    []
  );

  const handleChangeYear = (_event: React.MouseEvent<HTMLElement>, newYear: number) => {
    setSelectedYear(newYear);
  };

  const handleHistory = ({ row }: GridRowParams<ICustomersWithAnnualInspection>) => {
    const documentData = row.warehouseDocuments?.[0];
    if (!documentData) return;

    showModal(<DetailModal handleClose={closeModal} data={documentData} />);
  };

  const getRowClassName = ({ row }: GridCellParams<ICustomersWithAnnualInspection>) => {
    if (row.warehouseDocuments && row.warehouseDocuments.length > 0) return `row--success`;

    return "";
  };

  return (
    <DataGrid
      id={"Ročná prehliadka kotla"}
      title={"Ročná prehliadka kotla"}
      actions={
        <ToggleButtonGroup color="primary" value={selectedYear} exclusive onChange={handleChangeYear} size="small">
          <ToggleButton value={currentYear - 2}>{currentYear - 2}</ToggleButton>
          <ToggleButton value={currentYear - 1}>{currentYear - 1}</ToggleButton>
          <ToggleButton value={currentYear}>{currentYear}</ToggleButton>
        </ToggleButtonGroup>
      }
      loading={loading}
      fullHeight
      rows={data?.customersWithAnnualInspection ?? []}
      columns={columns}
      disableRowSelectionOnClick
      getCellClassName={getRowClassName}
      initialState={{
        columns: {
          columnVisibilityModel: {
            city: false,
          },
        },
      }}
      onRowClick={handleHistory}
    />
  );
};

export default Inspections;
