import TextField, { TextFieldProps } from '@mui/material/TextField';

type IProps = TextFieldProps;

const ReadOnlyTextField = ({ ...props }: IProps) => {
  return (
    <TextField
      InputLabelProps={{ focused: false }}
      {...props}
      InputProps={{ readOnly: true }}
      disabled
      sx={{
        '& fieldset': {
          border: 'none',
        },
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: '#000000',
        },
        '& .MuiInputLabel-root.Mui-disabled': {
          color: 'rgba(0, 0, 0, 0.6)',
        },
        ...(props.multiline ? { borderLeft: '1px solid rgba(0, 0, 0, 0.12)' } : undefined),
      }}
    />
  );
};

export default ReadOnlyTextField;