import { useState, useEffect } from "react";

import { MenuItem, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import BackdropLoading from "components/elements/BackdropLoading";
import Select from "components/elements/Select";
import {
  useAPICategories,
  useAPICreateArticle,
  useAPICurrencies,
  useAPISubcategoriesByCategory,
  useAPIUnits,
  useAPIUpdateArticle,
} from "hooks/useAPI";
import { enqueueSnackbar } from "notistack";
import { IArticleInput } from "types";

interface IProps extends Partial<IArticleInput> {
  handleClose: () => void;
  refetch: () => void;
}

const AddModal = ({ handleClose, refetch, id, ...props }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>(props.name ?? "");
  const [note, setNote] = useState<string>(props.note ?? "");
  const [unitId, setUnitId] = useState<number | undefined>(props.unitId);
  const [currencyId, setCurrencyId] = useState<number | undefined>(props.currencyId);
  const [price, setPrice] = useState<number | undefined>(props.price);
  const [priceVat, setPriceVat] = useState<number | undefined>(props.priceVat);
  const [manufacturer, setManufacturer] = useState<string>(props.manufacturer ?? "");
  const [categoryId, setCategoryId] = useState<number | undefined>(props.categoryId);
  const [subcategoryId, setSubcategoryId] = useState<number | undefined>(props.subcategoryId);

  const [isInit, setIsInit] = useState(false);
  const [createArticle] = useAPICreateArticle();
  const [updateArticle] = useAPIUpdateArticle();

  const { data: unitsData } = useAPIUnits();
  const { data: currenciesData } = useAPICurrencies();
  const { data: categoriesData } = useAPICategories();
  const { data: subcategoriesData } = useAPISubcategoriesByCategory(categoryId);

  useEffect(() => {
    if (categoryId && isInit) setSubcategoryId(undefined);
  }, [categoryId]);

  useEffect(() => {
    if (id && subcategoryId) setIsInit(true);
    if (!id) setIsInit(true);
  }, [id, isInit, subcategoryId]);

  const handleCreate = async () => {
    if (!unitId || !categoryId || !price || !priceVat || !subcategoryId || !currencyId) return;
    setLoading(true);
    try {
      await createArticle({
        variables: {
          createArticleInput: {
            name,
            note,
            unitId,
            currencyId,
            price,
            priceVat,
            manufacturer,
            categoryId,
            subcategoryId,
          },
        },
      });
      handleClose();
      refetch();
      enqueueSnackbar("Tovar bol úspešne vytvorený", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Tovar sa nepodarilo vytvoriť", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    if (!unitId || !categoryId || !price || !priceVat || !subcategoryId || !currencyId) return;
    setLoading(true);
    try {
      await updateArticle({
        variables: {
          updateArticleInput: {
            id,
            name,
            note,
            unitId,
            currencyId,
            price,
            priceVat,
            manufacturer,
            categoryId,
            subcategoryId,
          },
        },
      });
      handleClose();
      refetch();
      enqueueSnackbar("Tovar bol úspešne upravený", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Tovar sa nepodarilo upraviť", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const isPossibleToSubmit = () =>
    name.length > 0
    && unitId !== undefined
    && currencyId !== undefined
    && price !== undefined
    && priceVat !== undefined
    && categoryId !== undefined
    && subcategoryId !== undefined;

  const handleSubmit = () => (id ? handleUpdate() : handleCreate());

  return (
    <Dialog open={true} fullWidth maxWidth="md">
      <DialogTitle>{id ? "Upraviť" : "Pridať"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} mt={1}>
          <Grid xs={12} sm={6.5}>
            <Stack spacing={1}>
              <TextField fullWidth label="Názov" required value={name} onChange={(e) => setName(e.target.value)} />
              <Stack direction={"row"} spacing={1}>
                <TextField
                  fullWidth
                  required
                  label="Cena"
                  type="number"
                  value={price ?? ""}
                  onChange={(e) => setPrice(e.target.value ? Number(e.target.value) : undefined)}
                />
                <Button variant="outlined" size="small" onClick={() => setPrice(priceVat ? Number((priceVat / 1.2).toFixed(2)) : undefined)}>
                  Vypočítaj
                </Button>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <TextField
                  fullWidth
                  required
                  label="Cena s DPH"
                  type="number"
                  value={priceVat ?? ""}
                  onChange={(e) => setPriceVat(e.target.value ? Number(e.target.value) : undefined)}
                />
                <Button variant="outlined" size="small" onClick={() => setPriceVat(price ? Number((price * 1.2).toFixed(2)) : undefined)}>
                  Vypočítaj
                </Button>
              </Stack>
              <TextField
                fullWidth
                label="Výrobca"
                value={manufacturer}
                onChange={(e) => setManufacturer(e.target.value)}
              />
            </Stack>
          </Grid>
          <Grid xs={12} sm={5.5}>
            <Stack spacing={1}>
              <Select
                label="Mena"
                required
                value={currencyId ?? ""}
                disabled={currenciesData?.currencies.length === 0 || !currenciesData?.currencies}
                onChange={(e) => setCurrencyId(e.target.value as number)}>
                {currenciesData?.currencies.map((currency) => (
                  <MenuItem key={currency.id} value={currency.id}>
                    {currency.name}
                  </MenuItem>
                ))}
              </Select>
              <Select
                label="Merná jednotka"
                required
                value={unitId ?? ""}
                disabled={unitsData?.units.length === 0 || !unitsData?.units}
                onChange={(e) => setUnitId(e.target.value as number)}>
                {unitsData?.units.map((unit) => (
                  <MenuItem key={unit.id} value={unit.id}>
                    {unit.name}
                  </MenuItem>
                ))}
              </Select>
              <Select
                label="Kategória"
                required
                value={categoryId ?? ""}
                disabled={categoriesData?.categories.length === 0 || !categoriesData?.categories}
                onChange={(e) => setCategoryId(e.target.value as number)}>
                {categoriesData?.categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
              <Select
                label="Podkategória"
                required
                value={subcategoryId ?? ""}
                disabled={
                  subcategoriesData?.subcategoriesByCategory.length === 0 || !subcategoriesData?.subcategoriesByCategory
                }
                onChange={(e) => setSubcategoryId(e.target.value as number)}>
                {subcategoriesData?.subcategoriesByCategory.map((subcategory) => (
                  <MenuItem key={subcategory.id} value={subcategory.id}>
                    {subcategory.name}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>
          <Grid xs={12}>
            <TextField
              fullWidth
              label="Poznámka"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              rows={3}
              multiline
            />
          </Grid>
        </Grid>
        <BackdropLoading loading={loading} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="small" onClick={handleClose}>
          Zrušiť
        </Button>
        <Button variant="contained" size="small" disabled={!isPossibleToSubmit()} onClick={handleSubmit}>
          {id ? "Upraviť" : "Vytvoriť"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddModal;
