import React, { useMemo, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Stack } from "@mui/material";
import { GridActionsCellItem, GridColDef, GridColType, GridRowParams } from "@mui/x-data-grid";
import BackdropLoading from "components/elements/BackdropLoading";
import DataGrid from "components/elements/DataGrid";
import SnackbarCloseButton from "components/elements/SnackbarCloseButton";
import axiosClient from "config/AxiosClient";
import { useModalContext } from "context/ModalContext";
import { useUserDataContext } from "context/UserDataContext";
import { useAPICustomersByType, useAPIRemoveCustomer, useAPIRemoveCustomers } from "hooks/useAPI";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar } from "notistack";
import { ICustomer, IImportResult } from "types";
import { CustomerType } from "types/enums";
import { formatDate, formatPostalCode } from "utils";

import AddModal from "./AddModal";
import HistoryModal from "./HistoryModal";

interface IProps {
  type: CustomerType;
}

const Customers: React.FC<IProps> = ({ type }) => {
  const [isImporting, setIsImporting] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const { showModal, closeModal } = useModalContext();
  const { isAdmin } = useUserDataContext();
  const { loading, data, refetch } = useAPICustomersByType(type);
  const [removeCustomer] = useAPIRemoveCustomer();
  const [removeCustomers] = useAPIRemoveCustomers();
  const confirm = useConfirm();

  const columns: GridColDef<ICustomer>[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Meno",
        flex: 1,
        renderCell: (params) => (
          <Box title="Zobraziť históriu" style={{ cursor: "pointer" }} onClick={() => handleHistory(params.row)}>
            {params.value}
          </Box>
        ),
      },
      { field: "address", headerName: "Adresa", flex: 1 },
      { field: "city", headerName: "Mesto", flex: 1 },
      {
        field: "postalCode",
        headerName: "PSČ",
        width: 70,
        valueFormatter: (_value, row) => (row.postalCode ? formatPostalCode(row.postalCode) : row.postalCode),
      },
      { field: "country", headerName: "Krajina", width: 100 },
      { field: "ico", headerName: "IČO", width: 90 },
      { field: "dic", headerName: "DIČ", width: 90 },
      { field: "email", headerName: "Email", flex: 1 },
      {
        field: "phone",
        headerName: "Telefón",
        width: 140,
      },
      { field: "note", headerName: "Poznámka", width: 80 },
      {
        field: "createdAt",
        headerName: "Vytvorené",
        width: 100,
        type: "date",
        valueFormatter: (_value, row) => (row.createdAt ? formatDate(row.createdAt) : row.createdAt),
      },
      ...(isAdmin
        ? [
            {
              field: "actions",
              type: "actions" as GridColType,
              width: 120,
              getActions: (params: GridRowParams<ICustomer>) => [
                <GridActionsCellItem icon={<CreateIcon />} label="Edit" onClick={() => handleEdit(params.row)} />,
                <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => handleDelete(params.row)} />,
              ],
            },
          ]
        : []),
    ],
    [type, isAdmin]
  );

  const handleCreate = () => {
    showModal(<AddModal handleClose={closeModal} refetch={refetch} type={type} />);
  };

  const handleEdit = (row: ICustomer) => {
    showModal(<AddModal handleClose={closeModal} refetch={refetch} type={type} {...row} />);
  };

  const handleHistory = (row: ICustomer) => {
    showModal(<HistoryModal handleClose={closeModal} id={row.id} type={type} name={row.name} />);
  };

  const handleDelete = (row: ICustomer) => {
    confirm({
      title: "Naozaj chcete vymazať tohto zákazníka?",
      description: `${row.name} bude vymazaný. Táto akcia je nevratná.`,
    })
      .then(async () => {
        try {
          await removeCustomer({ variables: { removeCustomerId: row.id } });
          refetch();
          enqueueSnackbar("Zákazník bol vymazaný", { variant: "success" });
        } catch (e) {
          enqueueSnackbar("Zákazníka sa nepodarilo vymazať", { variant: "error" });
        }
      })
      .catch(() => {
        /* ... */
      });
  };

  const handleImport = async (file: File) => {
    setIsImporting(true);
    try {
      const formData = new FormData();
      formData.append("file", file);

      const { data } = await axiosClient.post<IImportResult>(
        `/files/import-${type === CustomerType.BUYER ? "buyers" : "suppliers"}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (data.failImportedRows.length > 0 && data.imported === 0) {
        enqueueSnackbar("Import sa nepodaril", { variant: "error" });
      } else if (data.failImportedRows.length > 0 && data.imported > 0) {
        enqueueSnackbar(
          "Import bol čiastočne úspešný, nepodarilo sa importovať nasledujúce riadky: "
            + data.failImportedRows.join(", "),
          {
            variant: "warning",
            action: SnackbarCloseButton,
            persist: true,
          }
        );
        refetch();
      } else {
        enqueueSnackbar("Import bol úspešný", { variant: "success" });
        refetch();
      }
    } catch (error) {
      enqueueSnackbar("Import sa nepodaril", { variant: "error" });
    }
    setIsImporting(false);
  };

  const handleBulkDelete = () => {
    confirm({
      title: "Naozaj chcete vymazať zákazníkov?",
      description: `Vybraný zákazníci (${selectedRows.length}) budú vymazaný. Táto akcia je nevratná.`,
    })
      .then(async () => {
        try {
          const { data } = await removeCustomers({ variables: { removeCustomerIds: selectedRows } });
          refetch();

          if (data?.removeCustomers?.nonDeletedIds && data?.removeCustomers?.nonDeletedIds.length > 0) {
            enqueueSnackbar(
              `Nepodarilo sa vymazať nasledujúcich zákazníkov: ${data.removeCustomers.nonDeletedIds.join(", ")}`,
              { variant: "warning", action: SnackbarCloseButton, persist: true }
            );
          } else {
            enqueueSnackbar("Vybraný zákazníci boli vymazaný", { variant: "success" });
          }
        } catch (e) {
          enqueueSnackbar("Vybraných zákazníkov sa nepodarilo vymazať", { variant: "error" });
        }
      })
      .catch(() => {
        /* ... */
      });
  };

  return (
    <>
      <DataGrid
        id={type === CustomerType.BUYER ? "odberatelia" : "dodavatelia"}
        title={type === CustomerType.BUYER ? "Odberatelia" : "Dodávatelia"}
        actions={
          isAdmin ? (
            <Stack direction={"row"} spacing={1}>
              <Button variant="contained" size="small" onClick={handleCreate} endIcon={<AddIcon />}>
                Pridať
              </Button>
              {selectedRows.length > 1 && (
                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  onClick={handleBulkDelete}
                  endIcon={<DeleteIcon />}>
                  {`Odstrániť (${selectedRows.length})`}
                </Button>
              )}
            </Stack>
          ) : undefined
        }
        loading={loading}
        fullHeight
        rows={data?.customersByType ?? []}
        columns={columns}
        onImport={isAdmin ? handleImport : undefined}
        checkboxSelection={isAdmin}
        disableRowSelectionOnClick
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows
            = data?.customersByType.filter((row) => selectedIDs.has(row.id)).map((row) => row.id) ?? [];
          setSelectedRows(selectedRows);
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              ico: false,
              dic: false,
              email: false,
              phone: false,
              city: false,
              createdAt: false,
              country: false,
              postalCode: false,
            },
          },
        }}
      />
      <BackdropLoading loading={isImporting} />
    </>
  );
};

export default Customers;
