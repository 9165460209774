import { useState, useEffect } from "react";

import { useUserDataContext } from "context/UserDataContext";
import { useAPIUserInfo } from "hooks/useAPI";
import { useNavigate } from "react-router-dom";

const useAppInit = () => {
  const navigate = useNavigate();
  const [mutateFunction] = useAPIUserInfo();
  const [isReadyToRender, setIsReadyToRender] = useState(false);
  const { setUserData } = useUserDataContext();

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const { data } = await mutateFunction();
        if (data) setUserData?.(data.userInfo);
      } catch (error) {
        navigate("/login");
      } finally {
        setIsReadyToRender(true);
      }
    };

    getUserInfo();
  }, []);

  return {
    isReadyToRender,
  };
};

export default useAppInit;
