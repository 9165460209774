import React from "react";

import Grid from "@mui/material/Unstable_Grid2";
import ReadOnlyTextField from "components/elements/ReadOnlyTextField";

interface IProps {
  name: string;
  quantity: number;
  price: number;
  unit: string;
}

const Item: React.FC<IProps> = ({ name, quantity, price, unit }) => {
  const getTotalPrice = () => {
    const totalPrice = quantity * price;
    const value = totalPrice.toFixed(2).replace(".", ",");

    return value;
  };

  return (
    <Grid container spacing={1} pb={1}>
      <Grid xs={12} sm={5}>
        <ReadOnlyTextField size="small" label="Názov" value={name} />
      </Grid>
      <Grid xs={4} sm={2}>
        <ReadOnlyTextField size="small" label="Množstvo" value={quantity} />
      </Grid>
      <Grid xs={3.5} sm={1}>
        <ReadOnlyTextField size="small" label="MJ" value={unit} />
      </Grid>
      <Grid xs={4.5} sm={2}>
        <ReadOnlyTextField size="small" label="JC" value={price} />
      </Grid>
      <Grid xs={10} sm={2}>
        <ReadOnlyTextField size="small" label="Spolu" value={getTotalPrice()} />
      </Grid>
    </Grid>
  );
};

export default Item;
