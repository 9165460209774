import React, { useMemo } from "react";

import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import { GridActionsCellItem, GridColDef, GridColType, GridRowParams } from "@mui/x-data-grid";
import DataGrid from "components/elements/DataGrid";
import { useModalContext } from "context/ModalContext";
import { useUserDataContext } from "context/UserDataContext";
import { useAPICurrencies, useAPIRemoveCurrency } from "hooks/useAPI";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar } from "notistack";
import { ICurrency, IUnit } from "types";

import AddModal from "./AddModal";
import EditModal from "./EditModal";

const Currencies: React.FC = () => {
  const { showModal, closeModal } = useModalContext();
  const { isAdmin } = useUserDataContext();
  const { loading, data, refetch } = useAPICurrencies();
  const [removeUnit] = useAPIRemoveCurrency();
  const confirm = useConfirm();

  const columns: GridColDef<ICurrency>[] = useMemo(
    () => [
      { field: "id", headerName: "ID", flex: 1 },
      { field: "name", headerName: "Názov", flex: 1 },
      { field: "description", headerName: "Popis", flex: 1 },
      ...(isAdmin
        ? [
            {
              field: "actions",
              type: "actions" as GridColType,
              width: 80,
              getActions: (params: GridRowParams<ICurrency>) => [
                <GridActionsCellItem icon={<CreateIcon />} label="Edit" onClick={() => handleEdit(params.row)} />,
                <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => handleDelete(params.row)} />,
              ],
            },
          ]
        : []),
    ],
    [isAdmin]
  );

  const handleCreate = () => {
    showModal(<AddModal handleClose={closeModal} refetch={refetch} />);
  };

  const handleEdit = (row: IUnit) => {
    showModal(<EditModal handleClose={closeModal} refetch={refetch} {...row} />);
  };

  const handleDelete = (row: ICurrency) => {
    confirm({
      title: "Naozaj chcete vymazať túto menu?",
      description: `${row.name} bude vymazaná. Táto akcia je nevratná.`,
    })
      .then(async () => {
        try {
          await removeUnit({ variables: { removeCurrencyId: row.id } });
          refetch();
          enqueueSnackbar("Mena bola vymazaná", { variant: "success" });
        } catch (e) {
          enqueueSnackbar("Menu sa nepodarilo vymazať", { variant: "error" });
        }
      })
      .catch(() => {
        /* ... */
      });
  };

  return (
    <DataGrid
      id="meny"
      title={"Meny"}
      actions={
        isAdmin ? (
          <Button variant="contained" size="small" onClick={handleCreate} endIcon={<AddIcon />}>
            Pridať
          </Button>
        ) : undefined
      }
      fullHeight
      loading={loading}
      rows={data?.currencies ?? []}
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: "id",
              sort: "asc",
            },
          ],
        },
      }}
    />
  );
};

export default Currencies;
